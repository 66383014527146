import {
  InventoryItem,
  RestrictionAndValuesOption,
} from './channel-manager.type';

export const roomTypeConfig: RestrictionAndValuesOption[] = [
  {
    label: 'Available',
    value: null,
    type: 'number',
  },
  {
    label: 'Stop Sell',
    value: 'STOP_SELL',
    type: 'boolean',
  },
];

export const rateAndRestrictions: RestrictionAndValuesOption[] = [
  {
    label: 'Rate',
    value: 'rates',
    type: 'number',
  },
];

export const extrasConfig: InventoryItem[] = [
  {
    name: 'Child Range 1',
    key: 'childRangeOne',
    order: true,
    baseRate: null,
    id: null,
    isBase: false,
    value: null,
  },
  {
    name: 'Child Range 2',
    key: 'childRangeTwo',
    order: false,
    baseRate: null,
    id: null,
    isBase: false,
    value: null,
  },
  {
    name: 'Child Range 3',
    key: 'childRangeThree',
    order: false,
    baseRate: null,
    id: null,
    isBase: false,
    value: null,
  },
  {
    name: 'Extra Adult',
    key: 'adultPrice',
    order: false,
    baseRate: null,
    id: null,
    isBase: false,
    value: null,
  },
];

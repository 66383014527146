<div class="date-filter-wrapper">
  <img
    class="date-filter-wrapper__prev"
    (click)="handleDateIntervalChange('LEFT')"
    src="assets/svg/left.svg"
    alt="left"
  />
  <ng-container *ngFor="let date of dates; let i = index">
    <div
      (click)="onSelectedDateRangeChange(dates[i], i)"
      class="card"
      [ngClass]="{ card__selected: selectedDateFilter === date.date }"
    >
      <span> {{ date.day }}</span>
      <span>{{ date.date }}</span>
    </div>
  </ng-container>
  <img
    class="date-filter-wrapper__next"
    (click)="handleDateIntervalChange('RIGHT')"
    src="assets/svg/right.svg"
    alt="right"
  />
</div>

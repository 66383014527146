<div class="caption__wrapper--buttons" [formGroup]="controlContainer.control">
  <div class="button__wrapper">
    <mat-chip-list
      multiple
      #chipList
      aria-label="Quick Reply selection"
      [formControlName]="controlName"
    >
      <mat-chip
        *ngFor="let chip of chips; let chipIdx = index"
        selectable="true"
        (click)="toggleQuickReplyFilter(chipIdx)"
        [ngStyle]="{
          borderColor: chip?.color,
          color:
            chip.isSelected || selectedChips.has(chip.value)
              ? '#ffff'
              : chip?.color,
          backgroundColor:
            chip.isSelected || selectedChips.has(chip.value) ? chip?.color : ''
        }"
        [ngClass]="{
          'chip-outlined-default': chip.type === 'default',
          'chip-contained-default':
            chip.type === 'default' &&
            (chip.isSelected || selectedChips.has(chip.value)),

          'chip-outlined-active': chip.type === 'active',
          'chip-contained-active':
            chip.type === 'active' &&
            (chip.isSelected || selectedChips.has(chip.value)),

          'chip-outlined-failed': chip.type === 'failed',
          'chip-contained-failed':
            chip.type === 'failed' &&
            (chip.isSelected || selectedChips.has(chip.value)),

          'chip-outlined-inactive': chip.type === 'inactive',
          'chip-contained-inactive':
            chip.type === 'inactive' &&
            (chip.isSelected || selectedChips.has(chip.value)),

          'chip-outlined-draft': chip.type === 'draft',
          'chip-contained-draft':
            chip.type === 'draft' &&
            (chip.isSelected || selectedChips.has(chip.value)),

          'chip-outlined-completed': chip.type === 'completed',
          'chip-contained-completed':
            chip.type === 'completed' &&
            (chip.isSelected || selectedChips.has(chip.value)),

          'chip-outlined-success': chip.type === 'success',
          'chip-contained-success':
            chip.type === 'success' &&
            (chip.isSelected || selectedChips.has(chip.value)),

          'chip-outlined-warning': chip.type === 'warning',
          'chip-contained-warning':
            chip.type === 'warning' &&
            (chip.isSelected || selectedChips.has(chip.value)),

          'chip-outlined-unavailable': chip.type === 'unavailable',
          'chip-contained-unavailable':
            chip.type === 'unavailable' &&
            (chip.isSelected || selectedChips.has(chip.value))
        }"
      >
        <span> {{ chip.label }}</span>
        <span *ngIf="chip.total >= 0 && isCountVisible">
          ({{ chip.total }})</span
        >
      </mat-chip>
    </mat-chip-list>
    <ng-content></ng-content>
    <!-- <img src="assets/svg/Filter-Icon.svg" alt="filter" /> -->
  </div>
</div>

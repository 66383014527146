<p-accordion>
  <p-accordionTab
    class="accordion-header"
    header="Social Media"
    [formGroup]="useForm"
  >
    <div class="form-block" formArrayName="socialPlatforms">
      <div
        class="one-third-width"
        *ngFor="let platform of socialMediaControl.controls; let i = index"
        [formGroupName]="i"
      >
        <div class="social-media">
          <img
            [src]="socialMediaControl.controls[i].get('imageUrl').value"
            alt="img"
            class="facebook"
          />
          <hospitality-bot-input
            controlName="redirectUrl"
            class="input"
            [props]="{
              placeholder: 'Enter URL',
              errorMessages: {
                invalidUrl: 'Please enter valid url.'
              }
            }"
          ></hospitality-bot-input>
          <img
            *ngIf="socialMediaControl.controls[i].get('redirectUrl').value"
            src="/assets/svg/cross.svg"
            (click)="
              socialMediaControl.controls[i].get('redirectUrl').setValue('')
            "
            alt="cross"
            class="cross"
          />
        </div>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>

import { Injectable } from '@angular/core';
import { ApiService } from 'libs/shared/utils/src/lib/services/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Option } from '../types/form.type';
import { ConfigurationResponse } from '../types/config-response.type';
import { findCurrencySymbol } from '../configs/currency';

@Injectable({ providedIn: 'root' })
export class ConfigService extends ApiService {
  $config = new BehaviorSubject<ConfigurationResponse>(null);
  $isDayBookingAvailable = new BehaviorSubject<boolean>(true);

  /**
   * Static properties
   */
  public static defaultCurrency: string;
  public static currencyOptions: Option[] = [];
  public static currencySymbol: string = 'INR';

  getColorAndIconConfig(entityId: string): Observable<ConfigurationResponse> {
    return this.get(`/api/v1/cms/entity/${entityId}/configuration`);
  }

  getCountryCode(): Observable<any> {
    return this.get('/api/v1/config?key=COUNTRYCODE');
  }

  getDayBookingConfiguration(entityId: string): Observable<any> {
    return this.get(
      `/api/v1/configurations/frontdesk?entity-id=${entityId}&configType=DAY_BOOKING`
    );
  }

  getOTASourceIcon(value: string): string {
    const OTAConfig = this.$config.value.bookingConfig.source.find(
      (item) => item.name === 'OTA'
    );
    return OTAConfig.type.find(
      (item) =>
        item.label.toLowerCase() === value.toLowerCase() ||
        value.toLowerCase() === item.code.toLowerCase()
    )?.icon;
  }

  initConfiguration(response: ConfigurationResponse) {
    this.$config.next(response);
    this.initCurrencyConfig();
  }

  initCurrencyConfig() {
    ConfigService.currencyOptions = this.$config?.value?.currencyConfiguration.map(
      (item) => ({
        label: item.key,
        value: item.key,
      })
    );
    ConfigService.defaultCurrency = this.$config?.value?.currencyConfiguration?.[0]?.key;

    ConfigService.currencySymbol = findCurrencySymbol(
      ConfigService.defaultCurrency
    );
  }
}

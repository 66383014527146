<div class="wrapper">
  <div class="header-content">
    <ng-container
      *skeleton="label == undefined; height: '25px'; width: '200px'"
    ></ng-container>
    <hospitality-bot-custom-tooltip [text]="tooltip" *ngIf="tooltip; else body">
      <div class="stat-title">
        {{ label }}
      </div>
    </hospitality-bot-custom-tooltip>
    <ng-template #body
      ><div class="stats-text">
        {{ label }}
      </div></ng-template
    >
    <div
      *skeleton="
        key == undefined;
        borderRadius: '50%';
        height: '50px';
        width: '50px'
      "
    >
      <img class="icons" [src]="getImageUrl(key)" alt="" />
    </div>
  </div>
  <div
    class="info"
    *skeleton="
      additionalData === undefined;
      height: '48px';
      width: '200px';
      margin: '30px 0 0 0'
    "
  >
    <div
      *ngIf="additionalData?.length && statCardType === 'single'"
      class="amount"
    >
      {{ additionalData || 0 }}
    </div>
    <div *ngIf="isComparisonPercent">
      <div class="percent">
        <div
          class="comparison-percent"
          [style.color]="comparisonPercent < 0 ? 'red' : 'green'"
          *ngIf="comparisonPercent !== undefined"
        >
          ({{ comparisonPercent }}%)
        </div>
        <img
          *ngIf="comparisonPercent !== undefined"
          [src]="
            comparisonPercent < 0
              ? adminSharedConfig.img.downArrow.url
              : adminSharedConfig.img.upArrow.url
          "
          [alt]="
            comparisonPercent < 0
              ? adminSharedConfig.img.downArrow.alt
              : adminSharedConfig.img.upArrow.alt
          "
        />
      </div>
      <!-- <div *ngIf="comparisonPercent" class="compared-dates">
        In 30 Days
      </div> -->
    </div>
  </div>
  <div class="graph">
    <ng-content></ng-content>

    <ng-container *ngIf="statCardType === 'multiple'">
      <div class="additional-stats-container">
        <div class="stats-wrapper" *ngFor="let data of additionalData">
          <span class="stats">{{ data.value }}</span>
          <div class="text">{{ data.label }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div [formGroup]="addressForm" class="input-wrapper">
  <hospitality-bot-auto-complete
    controlName="addressData"
    [label]="label"
    class="auto-complete"
    [options]="addressList"
    [loading]="loading"
    (onSearch)="searchOptions($event)"
    [settings]="{
      multiple: false
    }"
    [props]="{
      errorMessages: {
        required: 'This is a required field.'
      },
      placeholder: 'Search Address'
    }"
  >
  </hospitality-bot-auto-complete>

  <div hidden="true">{{ getValidator }}</div>
</div>

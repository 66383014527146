<div class="wrapper">
  <ng-container *ngIf="!!additionalActionItems.length">
    <div class="icons">
      <img
        *ngFor="let act of additionalActionItems"
        [src]="act.icon"
        [alt]="act.value"
        (click)="handleActionEmission(act.value)"
        class="svg"
      />
    </div>
    <div class="separator"></div>
  </ng-container>
  <div
    [formGroup]="controlContainer?.control.get(controlGroupName)"
    class="form-group-wrapper"
  >
    <p-dropdown
      class="export-dropdown"
      [options]="documentActionTypes"
      [formControlName]="documentActionTypeName"
      [disabled]="isDisable"
    >
      <ng-template let-documentActionItem pTemplate="selectedItem">
        <img src="assets/svg/Export.svg" alt="export" />
        <span class="databaseActionLabel">{{ documentActionItem.label }}</span>
      </ng-template>
    </p-dropdown>
    <div class="divider"></div>
    <p-dropdown
      class="export-dropdown"
      [options]="documentTypes"
      [formControlName]="documentTypesName"
      [disabled]="isDisable"
    >
      <ng-template let-documentTypeItem pTemplate="selectedItem">
        <img src="assets/svg/CSV.svg" alt="csv" />
        <span class="databaseActionLabel">{{ documentTypeItem.label }}</span>
      </ng-template>
    </p-dropdown>
    <button class="go" [disabled]="isDisable" (click)="handleSubmission()">
      <mat-icon class="material-icons" matSuffix>east</mat-icon>
    </button>
  </div>
</div>

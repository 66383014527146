<div
  class="wrapper"
  [ngStyle]="{ minHeight: height ? height : 'calc(100vh - 330px)' }"
>
  <div class="nav-actions">
    <ng-content></ng-content>
  </div>
  <div class="wrapper__standard" *ngIf="!isLoading; else loader">
    <img class="wrapper__standard-img" [src]="imageSrc" [alt]="imageSrc" />
    <p class="wrapper__standard-subText" [ngClass]="{ isError: isError }">
      {{ description }}
    </p>
    <hospitality-bot-button
      [disabled]="disabledAction"
      *ngIf="actionName?.length"
      [label]="actionName"
      [routerLink]="link"
      [href]="href"
      preserveQueryParams="true"
      (onClick)="action.emit()"
    ></hospitality-bot-button>
  </div>
</div>
<ng-template #loader>
  <hospitality-bot-loader-bounce class="loader"></hospitality-bot-loader-bounce>
</ng-template>

<div>
  <div class="row">
    <!-- Here header will come -->
  </div>
  <form [formGroup]="requestPasswordForm">
    <div class="card">
      <h4 class="card--heading">
        {{ 'forgot_password_text' | translate }}
      </h4>
      <h3 class="card--quote">
        {{ 'submit_email_text' | translate }}
      </h3>
      <div class="card-form">
        <div class="form-row">
          <mat-form-field>
            <input
              type="text"
              matInput
              formControlName="email"
              placeholder="{{ 'placeholder.email' | translate }}"
            />
            <mat-error
              *ngIf="
                requestPasswordForm.get('email').touched &&
                requestPasswordForm.get('email').hasError('required')
              "
            >
              {{ 'validation.email_required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                requestPasswordForm.get('email').touched &&
                requestPasswordForm.get('email').hasError('pattern')
              "
            >
              {{ 'validation.validate_email' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <p class="note">{{ 'mail_presence_text' | translate }}</p>
      </div>
      <div class="card-footer">
        <button
          class="login"
          [disabled]="isRequesting"
          mat-button
          (click)="requestPassword()"
        >
          <i *ngIf="isRequesting" class="fa fa-spinner fa-spin"></i>
          {{ 'Request Password' }}
        </button>
      </div>
      <div class="back-button" (click)="navigateToLogin()">
        <mat-icon class="material-icon">keyboard_backspace</mat-icon>
        <mat-label class="label">{{ 'button.back' | translate }}</mat-label>
      </div>
    </div>
  </form>
</div>

<div class="wrapper">
  <!-- Interactive grid view -->
  <div #myBounds [ngStyle]="{
      width: noOfColumn * cellSize + 'px',
      height: noOfRow * height + 'px'
    }" class="interactive-grid-wrapper" *ngIf="showDataGrid">
    <ng-container *ngFor="let row of gridRows; let rowIdx = index">
      <ng-container *ngFor="let col of gridColumns; let colIdx = index">
        <ng-container *ngTemplateOutlet="
            cellTemplate;
            context: {
              myBounds: myBounds,
              row: row,
              col: col,
              rowIdx: rowIdx,
              colIdx: colIdx
            }
          "></ng-container>
      </ng-container>
    </ng-container>

    <!-- _S are the grid data at the start, needed to map is separately -->
    <ng-container *ngFor="let row of gridRows; let rowIdx = index">
      <ng-container *ngTemplateOutlet="
          cellTemplate;
          context: {
            myBounds: myBounds,
            row: row,
            col: gridColumns[0] + '_S',
            rowIdx: rowIdx,
            colIdx: 0
          }
        "></ng-container>
    </ng-container>
  </div>

  <!-- Presentation grid view -->
  <div class="grid-container" [ngStyle]="{ width: noOfColumn * cellSize + 'px' }">
    <div *ngFor="let row of gridRows; let rowIdx = index" class="grid-row" [id]="'myContainment_' + i">
      <div *ngFor="let col of gridColumns; let colIdx = index" class="grid-cell" [ngStyle]="{
          width: cellSize + 'px',
          height: height + 'px'
        }">
        <div [preventDefaultEvent]="true" class="non-drag-grid" zIndex="1" [ngStyle]="{
            width: cellSize - 2 * cellGap + 'px',
            height: height - 2 * cellGap + 'px',
            margin: cellGap + 'px',
            cursor: disableChanges ? 'default' : 'pointer'
          }" (click)="
            handleCreate({
              rowValue: row,
              colValue: col
            })
          " [pTooltip]="
            disableChanges ? undefined : 'Create'+' ' + createNewToolTipInfo
          " showDelay="500" tooltipPosition="top">
          <ng-container *ngIf="devMode && rowIdx === gridRows.length - 1">
            <div>
              idx: <b>{{ colIdx }}</b>
            </div>
            <div>
              val: <b>{{ col }}</b>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- Spinner -->
  <div class="grid-container spinner-wrapper" [ngStyle]="{
      width: noOfColumn * cellSize + 'px',
      height: noOfRow * height + 'px'
    }" *ngIf="isProcessing">
    <i class="pi pi-spin pi-spinner" [ngClass]="iconNgClass"></i>
  </div>
</div>

<!-- Interactive grid cell -->
<ng-template #cellTemplate let-row="row" let-col="col" let-rowIdx="rowIdx" let-colIdx="colIdx" let-myBounds="myBounds">
  <div class="drag-grid" [bounds]="myBounds" [rzContainment]="myBounds" [inBounds]="true"
    *ngIf="data[row] && data[row][col]" [ngDraggable]="
      isInteractive({
        rowValue: row,
        colValue: col,
        colIdx: colIdx,
        rowIdx: rowIdx
      }) && !disableChanges
    " [ngResizable]="
      isInteractive({
        rowValue: row,
        colValue: col,
        colIdx: colIdx,
        rowIdx: rowIdx
      }) && !disableChanges
    " [rzMinWidth]="cellSize / resizeDelimiter" [rzGrid]="cellSize / resizeDelimiter" [position]="{
      x:
        colIdx * cellSize +
        (data[row][col]?.hasPrev &&
        data[row][col].endPosIdx !== data[row][col].startPosIdx
          ? cellSize / 2
          : 0),
      y: rowIdx * height
    }" [gridSize]="height" [ngStyle]="{
      width:
        getWidth({
          rowValue: row,
          colValue: col,
          colIdx: colIdx,
          rowIdx: rowIdx
        }) + 'px',
      height: height + 'px',
      padding: cellGap + 'px',
      marginLeft: '-' + outOfBoundRecord[data[row][col].id].lSpace + 'px',
      opacity: data[row][col]?.opacity ? data[row][col]?.opacity : 1
    }" [zIndex]="
      getZIndex({
        rowValue: row,
        colValue: col,
        colIdx: colIdx,
        rowIdx: rowIdx
      })
    " zIndexMoving="100" [preventDefaultEvent]="true" rzHandles="e,w" (rzStop)="
      handleResizing($event, {
        rowValue: row,
        colValue: col,
        colIdx: colIdx,
        rowIdx: rowIdx
      })
    " (rzResizing)="
      onResizing($event, {
        rowValue: row,
        colValue: col,
        colIdx: colIdx,
        rowIdx: rowIdx
      })
    " (endOffset)="
      handleDrag($event, {
        rowValue: row,
        colValue: col,
        colIdx: colIdx,
        rowIdx: rowIdx
      })
    " (movingOffset)="
      onMoving($event, {
        rowValue: row,
        colValue: col,
        colIdx: colIdx,
        rowIdx: rowIdx
      })
    " (click)="
      handleClick({
        rowValue: row,
        colValue: col,
        colIdx: colIdx,
        rowIdx: rowIdx
      })
    " (contextmenu)="
      onRightClick($event, {
        rowValue: row,
        colValue: col,
        colIdx: colIdx,
        rowIdx: rowIdx
      })
    ">
    <div *ngIf="
        data[row][col]?.options?.length && toggleMenuId === data[row][col].id
      " class="menu" [ngClass]="{
        menu__se: toggleMenuPos === 'SE',
        menu__sw: toggleMenuPos === 'SW',
        menu__ne: toggleMenuPos === 'NE',
        menu__nw: toggleMenuPos === 'NW'
      }">
      <ng-container *ngFor="let option of data[row][col]?.options">
        <div class="menu__item" [ngStyle]="{
            fontSize: height / 2.3 + 'px'
          }" (click)="
            handleMenuClick($event, option, {
              rowValue: row,
              colValue: col,
              colIdx: colIdx,
              rowIdx: rowIdx
            })
          ">
          {{ option.label }}
        </div>
      </ng-container>
    </div>
    <div class="cell-data" [ngClass]="{
        'interactive-cell': isInteractive({
          rowValue: row,
          colValue: col,
          colIdx: colIdx,
          rowIdx: rowIdx
        }),
        'allow-click': !!data[row][col]?.allowAction,
        'right-click': !!data[row][col]?.allowAction?.includes('showMenu'),
        'success-cell': data[row][col]?.colorCode === 'success',
        'active-cell': data[row][col]?.colorCode === 'active',
        'inactive-cell': data[row][col]?.colorCode === 'inactive',
        'failed-cell': data[row][col]?.colorCode === 'failed',
        'warning-cell': data[row][col]?.colorCode === 'warning',
        'draft-cell': data[row][col]?.colorCode === 'draft',
        'transparent-draft-cell': data[row][col]?.colorCode === 'transparent'
      }" [ngStyle]="{
        height: height - 2 * cellGap + 'px',
        borderLeft: outOfBoundRecord[data[row][col].id].hasLeftBorder
          ? null
          : 0,
        borderRight: outOfBoundRecord[data[row][col].id].hasRightBorder
          ? null
          : 0
      }" [pTooltip]="
        isInteractive({
          rowValue: row,
          colValue: col,
          colIdx: colIdx,
          rowIdx: rowIdx
        })
          ? 'Edit ' + data[row][col]?.content + ' ' + createNewToolTipInfo
          : data[row][col]?.additionContent
      " showDelay="500" tooltipPosition="top">
      <div class="cell-data__content" [ngStyle]="{
          fontSize: height / 3.5 + 'px'
        }">
        <ng-container *ngIf="data[row][col]?.icons">
          <img *ngFor="let icon of data[row][col]?.icons" [src]="icon" alt="cell-icon" class="cell-data__icon"
            [ngStyle]="{
              height: height / 4 + 'px'
            }" />
        </ng-container>
        {{ data[row][col]?.content }}
      </div>
      <div class="cell-data__additional-content" [ngStyle]="{
          fontSize: height / 4.5 + 'px'
        }">
        <img class="cell-data__additional-content-icon" *ngIf="data[row][col]?.additionContentIcon"
          [src]="data[row][col]?.additionContentIcon" alt="Booking Indicator"
          [pTooltip]="data[row][col]?.additionContent" />
        {{ data[row][col]?.additionContent }}
      </div>
    </div>
  </div>
</ng-template>
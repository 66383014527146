import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { SnackBarService } from '@hospitality-bot/shared/material';
import { Regex } from '@hospitality-bot/admin/shared';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';

/**
 * @class Request password component
 */
@Component({
  selector: 'admin-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss'],
})
export class RequestPasswordComponent implements OnInit, OnDestroy {
  requestPasswordForm: UntypedFormGroup;
  isEmailSent: boolean;
  isRequesting: boolean;
  email: string;

  private $subscription = new Subscription();

  constructor(
    private _fb: UntypedFormBuilder,
    private _router: Router,
    private _authService: AuthService,
    private _snackbarService: SnackBarService
  ) {
    this.email = this._router?.getCurrentNavigation()?.extras?.state?.email;
    this.initRequestForm();
  }

  ngOnInit(): void {}

  /**
   * @function initRequestForm To initialize request password form.
   */
  initRequestForm(): void {
    this.requestPasswordForm = this._fb.group({
      email: [
        this.email,
        [Validators.required, Validators.pattern(Regex.EMAIL_REGEX)],
      ],
    });
  }

  /**
   * @function requestPassword To request for a new password.
   */
  requestPassword(): void {
    this.isRequesting = true;
    if (!this.requestPasswordForm.valid) {
      return;
    }
    const requestData = {
      email: this.requestPasswordForm?.get('email')?.value ?? ''.trim(),
    };

    this.$subscription.add(
      this._authService.forgotPassword(requestData).subscribe(
        (response) => {
          this.isEmailSent = response.status ?? false;
          this._snackbarService.openSnackBarAsText(response?.message, '', {
            panelClass: 'success',
          });
          this._router.navigate(['/auth/resend-password'], {
            state: {
              email: this.email,
            },
          });
          this.isRequesting = false;
        },
        (error) => {
          this.isRequesting = false;
        }
      )
    );
  }

  /**
   * @function navigateToLogin To navigate to login route.
   */
  navigateToLogin(): void {
    this._router.navigate(['/auth']);
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }
}

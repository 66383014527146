<div class="wrapper">
  <div class="services-wrapper">
    <div class="inner-services-header">
      <div>
        <p>Import Services</p>
      </div>
      <div class="search-box">
        <span class="p-input-icon-right">
          <i class="pi pi-search"></i>
          <form [formGroup]="searchForm">
            <input
              formControlName="searchText"
              type="text"
              pInputText
              placeholder="Search"
            />
          </form>
        </span>
      </div>
    </div>
    <hr class="line-separator" />
    <div class="tab-group">
      <form [formGroup]="useForm">
        <hospitality-bot-custom-select
          formControlName="serviceIds"
          [itemList]="filteredServices"
          [fullView]="true"
          [noRecordsAction]="{
            imageSrc: 'assets/images/empty-table-service.png',
            description: 'No services found'
          }"
          [loading]="loading"
          type="icon"
        ></hospitality-bot-custom-select>
      </form>
    </div>
    <div class="footer-buttons" *ngIf="!loading && compServices.length > 0">
      <hospitality-bot-button
        (onClick)="resetForm()"
        label="Reset"
        variant="outlined"
        severity="reset"
      ></hospitality-bot-button>
      <hospitality-bot-button
        (onClick)="saveForm()"
        label="Import"
      ></hospitality-bot-button>
    </div>
  </div>
</div>

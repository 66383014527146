import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { deepCopy } from '../../utils/shared';
import { ChartConfig } from '../../types/chart.type';
import { doughnutDisengagementConfig } from '../../constants/charts';
import { ChartData } from 'chart.js';
@Component({
  selector: 'hospitality-bot-doughnut',
  templateUrl: './doughnut.component.html',
  styleUrls: ['./doughnut.component.scss'],
})
export class DoughnutComponent implements OnInit {
  @ViewChild(BaseChartDirective) baseChart: BaseChartDirective;

  doughnutGraphData: ChartConfig<'doughnut'> = deepCopy(
    doughnutDisengagementConfig
  );
  doughnutTransparentGraphData: ChartConfig<'doughnut'> = deepCopy(
    doughnutDisengagementConfig
  );

  @Input() label: string;
  @Input() loading: boolean = false;
  @Input() selectedColor: string = '#e61042';

  @Output() selectedItemIndex = new EventEmitter<number>(null);

  selectedIndex: number = 0;
  backUpData: ChartData<'doughnut'>;

  @Input() set config(data: ChartData<'doughnut'>) {
    this.total = data?.datasets[0]?.data.reduce(
      (acc, curr) => +acc + +curr,
      0
    ) as number;
    this.backUpData = data;

    if (this.total) {
      this.initGraphData(data);
      const selectedIndex = this.findFirstNonZeroIndex(data);
      this.initTransparentGraph(selectedIndex);
      this.selectedItemIndex.emit(selectedIndex);
    } else {
      this.resetGraph();
    }
  }

  @Input() total: number = 0;

  initGraphData(data: ChartData<'doughnut'>) {
    this.doughnutGraphData = {
      ...this.doughnutGraphData,
      data: {
        ...this.doughnutGraphData.data,
        ...data,
      },
    };

    this.doughnutTransparentGraphData = {
      ...this.doughnutTransparentGraphData,
      data: {
        ...this.doughnutTransparentGraphData.data,
        ...data,
      },
    };
  }

  initTransparentGraph(selectedIndex: number) {
    const transparentColor = 'transparent';
    const backgroundColor: string[] = [];
    const borderColor: string[] = [];

    const transparentBackgroundColor: string[] = [];
    const transparentBorderColor: string[] = [];

    (this.backUpData.datasets[0].backgroundColor as string[]).forEach(
      (itemColor, dataIndex) => {
        if (selectedIndex !== dataIndex) {
          backgroundColor.push(itemColor);
          borderColor.push(transparentColor);

          transparentBackgroundColor.push(transparentColor);
          transparentBorderColor.push(transparentColor);
        } else {
          transparentBackgroundColor.push(this.selectedColor);
          borderColor.push(this.selectedColor);
          backgroundColor.push(this.selectedColor);
        }
      }
    );

    this.doughnutTransparentGraphData = {
      ...this.doughnutTransparentGraphData,
      data: {
        ...this.doughnutTransparentGraphData.data,
        datasets: [
          {
            ...this.doughnutTransparentGraphData.data.datasets[0],
            backgroundColor: transparentBackgroundColor,
            borderColor: transparentBorderColor,
            hoverBackgroundColor: transparentBackgroundColor,
            hoverBorderColor: borderColor,
          },
        ],
      },
    };

    this.doughnutGraphData.data = {
      ...this.doughnutGraphData.data,
      datasets: [
        {
          ...this.doughnutGraphData.data.datasets[0],
          backgroundColor,
          borderColor,
          hoverBackgroundColor: backgroundColor,
          hoverBorderColor: borderColor,
        },
      ],
    };
  }

  resetGraph() {
    this.selectedItemIndex.emit(0);
    this.doughnutGraphData = deepCopy(doughnutDisengagementConfig);
    this.doughnutTransparentGraphData = deepCopy(doughnutDisengagementConfig);
  }

  constructor() {}

  ngOnInit(): void {}

  handleCircularGraphClick(data: any) {
    if (data.event.type === 'click' && this.total) {
      const clickedIndex = data.active[0]?.index;
      this.selectedItemIndex.emit(clickedIndex);
      this.initTransparentGraph(clickedIndex);
    }
  }

  findFirstNonZeroIndex(data: ChartData<'doughnut'>): number {
    return data.datasets[0].data.findIndex((item) => item !== 0);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { findCurrencySymbol } from '../configs/currency';
import { ConfigService } from '../services/config.service';

@Pipe({
  name: 'dynamicCurrency',
})
export class DynamicCurrencyPipe implements PipeTransform {
  transform(
    value: any,
    currencyCode?: string,
    display: 'code' | 'symbol' | 'symbol-narrow' | boolean = 'symbol',
    digits?: string,
    locale?: string
  ): string | null {
    if (isEmpty(value)) return null;
    locale = locale;
    let currency = currencyCode || ConfigService.defaultCurrency;
    if (display !== 'code') {
      currency = findCurrencySymbol(currency);
    }

    const { str } = formatNumber(value, locale, 'currency', digits, currency);

    return str;
  }
}

function isEmpty(value: any): boolean {
  return value == null || value === '' || value !== value;
}

// Custom number formatting function
function formatNumber(
  value: any,
  locale: string = 'en-US',
  style: 'decimal' | 'currency' = 'decimal',
  digits?: string,
  currencySymbol?: string // Use this to pass the custom symbol
): { str: string; error?: any } {
  try {
    // Create a formatter instance with given locale and style
    const options: Intl.NumberFormatOptions = {
      style: 'decimal', // Use 'decimal' style to avoid default currency symbols
      minimumFractionDigits: digits
        ? parseInt(digits.split('-')[0])
        : undefined,
      maximumFractionDigits: digits
        ? parseInt(digits.split('-')[1])
        : undefined,
    };

    // Create the formatter with the appropriate locale and options
    const formatter = new Intl.NumberFormat(locale, options);
    const formattedValue = formatter.format(value);

    // Append or prepend the custom currency symbol
    const result = currencySymbol
      ? `${currencySymbol}${formattedValue}` // Prepend the symbol
      : formattedValue; // Just return the formatted value if no symbol provided

    return { str: result };
  } catch (error) {
    console.error('Error formatting number:', error);
    return { str: '', error };
  }
}

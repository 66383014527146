export enum GuestTypes {
  primary = 'primary',
  secondary = 'secondary',
}

export enum GuestRole {
  sharer = 'sharer',
  accompany = 'accompany',
  kids = 'kids',
  undefined = '',
}

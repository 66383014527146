import { PermissionModuleNames } from './subscriptionConfig';

export const sharedConfig = {
  timeInterval: {
    date: 'date',
    week: 'week',
    month: 'month',
  },
  defaultOrder: 'DESC',
  img: {
    upArrow: {
      url: 'assets/svg/uparrow.svg',
      alt: 'uparrow',
    },
    downArrow: {
      url: 'assets/svg/down-percentage.svg',
      alt: 'down-percentage',
    },
  },
};

export const fileUploadConfiguration = {
  image: ['png', 'jpg', 'jpeg', 'gif', 'eps', 'webp', 'PNG'],
  video: ['mp4', 'MPEG', 'MOV', 'AVI', 'MKV'],
  docs: ['pdf', 'csv'],
};

export enum StatCardImageUrls {
  RemainingInventoryCost = 'assets/svg/remaining-inventory-cost.svg',
  InventoryRemaining = 'assets/svg/inventory-remaining.svg',
  Occupancy = 'assets/svg/occupancy.svg',
  AverageRoomRate = 'assets/svg/avg-room-rate.svg',
  Total = 'assets/svg/total.svg',
  ToDo = 'assets/svg/todo.svg',
  Resolved = 'assets/svg/resolved.svg',
  Timedout = 'assets/svg/timedout.svg',
  SellsGraph = 'assets/svg/avg-room-rate.svg',
  TotalOrders = 'assets/svg/remaining-inventory-cost.svg',
  InProgress = 'assets/svg/in-progress.svg',
  AverageTicket = 'assets/svg/tickets.svg',
  AverageTime = 'assets/svg/time-taken.svg',
  Agent = 'assets/svg/agent.svg',
  Group = 'assets/images/group-icon.png',
}

export enum DiscountType {
  PERCENTAGE = '%OFF',
  FLAT = 'Flat',
}

export const kidAgesList = [
  { label: '1 years', value: 1 },
  { label: '2 years', value: 2 },
  { label: '3 years', value: 3 },
  { label: '4 years', value: 4 },
  { label: '5 years', value: 5 },
  { label: '6 years', value: 6 },
  { label: '7 years', value: 7 },
  { label: '8 years', value: 8 },
  { label: '9 years', value: 9 },
  { label: '10 years', value: 10 },
  { label: '11 years', value: 11 },
  { label: '12 years', value: 12 },
  { label: '13 years', value: 13 },
  { label: '14 years', value: 14 },
  { label: '15 years', value: 15 },
  { label: '16 years', value: 16 },
  { label: '17 years', value: 17 },
  { label: '18 years', value: 18 },
  { label: '19 years', value: 19 },
  { label: '20 years', value: 20 },
  { label: '21 years', value: 21 },
  { label: '22 years', value: 22 },
  { label: '23 years', value: 23 },
  { label: '24 years', value: 24 },
  { label: '25 years', value: 25 },
];

export const guestSalutation = [
  { label: 'Mr.', value: 'Mr.' },
  { label: 'Ms.', value: 'Ms.' },
  { label: 'Mrs.', value: 'Mrs.' },
  { label: 'Air Commdr', value: 'Air Commdr' },
  { label: 'Dr.', value: 'Dr.' },
  { label: 'Bishop', value: 'Bishop' },
  { label: 'Air Marshal', value: 'Air Marshal' },
  { label: 'Baron', value: 'Baron' },
  { label: 'Brig.', value: 'Brig.' },
  { label: 'Baroness', value: 'Baroness' },
  { label: 'Capt.', value: 'Capt.' },
  { label: 'Cardinal', value: 'Cardinal' },
  { label: 'Countess', value: 'Countess' },
  { label: 'Admiral', value: 'Admiral' },
  { label: 'Duchess', value: 'Duchess' },
  { label: 'Duke', value: 'Duke' },
  { label: 'Col.', value: 'Col.' },
  { label: 'Consul', value: 'Consul' },
  { label: 'His Excellency', value: 'His Excellency' },
  { label: 'Earl', value: 'Earl' },
  { label: 'Lady', value: 'Lady' },
  { label: 'Lord', value: 'Lord' },
  { label: 'Father', value: 'Father' },
  { label: 'Sheikh', value: 'Sheikh' },
  { label: 'Sir', value: 'Sir' },
  { label: 'Gen.', value: 'Gen.' },
  { label: 'Viscount', value: 'Viscount' },
  { label: 'Viscountess', value: 'Viscountess' },
  { label: 'Air Vice Marshal', value: 'Air Vice Marshal' },
  { label: 'Gp. Capt.', value: 'Gp. Capt.' },
  { label: 'Khun', value: 'Khun' },
  { label: 'Khunying', value: 'Khunying' },
  { label: 'King', value: 'King' },
  { label: 'Lt.', value: 'Lt.' },
  { label: 'Maestro', value: 'Maestro' },
  { label: 'Maj.', value: 'Maj.' },
  { label: 'Pastor', value: 'Pastor' },
  { label: 'Prof.', value: 'Prof.' },
  { label: 'Master', value: 'Master' },
  { label: 'Prince', value: 'Prince' },
  { label: 'Princess', value: 'Princess' },
  { label: 'Queen', value: 'Queen' },
  { label: 'Reverend', value: 'Reverend' },
  { label: 'Maharaja', value: 'Maharaja' },
  { label: 'Maharani', value: 'Maharani' },
  { label: 'Yuvraj', value: 'Yuvraj' },
  { label: 'Yuvarani', value: 'Yuvarani' },
  { label: 'Senator', value: 'Senator' },
  { label: 'Sheikha', value: 'Sheikha' },
  { label: 'Sister', value: 'Sister' },
  { label: 'Wng. Cdr', value: 'Wng. Cdr' },
  { label: 'Comdt.', value: 'Comdt.' },
  { label: 'Sqdr. Ldr.', value: 'Sqdr. Ldr.' },
  { label: 'Thanpuying', value: 'Thanpuying' },
  { label: 'Ustad', value: 'Ustad' },
  { label: 'Shri', value: 'Shri' },
  { label: 'Smt', value: 'Smt' },
  { label: 'First Officer', value: 'First Officer' },
  { label: 'Consul General', value: 'Consul General' },
  { label: 'Chief Justice', value: 'Chief Justice' },
  { label: 'Chef', value: 'Chef' },
  { label: 'Rabbi', value: 'Rabbi' },
  { label: 'Flight Lieutenant', value: 'Flight Lieutenant' },
  { label: 'Her Excellency', value: 'Her Excellency' },
  { label: 'Air Chief Marshal', value: 'Air Chief Marshal' },
  { label: 'Ambassador', value: 'Ambassador' },
  { label: 'Chancellor', value: 'Chancellor' },
  { label: 'Commander', value: 'Commander' },
  { label: 'Corporal', value: 'Corporal' },
  { label: 'Count', value: 'Count' },
  { label: 'Dato', value: 'Dato' },
  { label: 'Field Marshal', value: 'Field Marshal' },
  { label: 'Governor', value: 'Governor' },
  { label: 'Her Highness', value: 'Her Highness' },
  { label: 'Her Majesty', value: 'Her Majesty' },
  { label: 'His Highness', value: 'His Highness' },
  { label: 'His Holiness', value: 'His Holiness' },
  { label: 'His Majesty', value: 'His Majesty' },
  { label: 'Hon. Chairman', value: 'Hon. Chairman' },
  { label: 'Hon. Minister', value: 'Hon. Minister' },
  { label: 'Judge', value: 'Judge' },
  { label: 'Justice', value: 'Justice' },
  { label: 'The Honourable', value: 'The Honourable' },
  { label: 'Lt. General', value: 'Lt. General' },
  { label: 'Major General', value: 'Major General' },
  { label: 'Minister', value: 'Minister' },
  { label: 'Pandit', value: 'Pandit' },
  { label: 'Raja', value: 'Raja' },
  { label: 'Rajkumar', value: 'Rajkumar' },
  { label: 'Rajkumari', value: 'Rajkumari' },
  { label: 'Rani', value: 'Rani' },
  { label: 'Rear Admiral', value: 'Rear Admiral' },
  { label: 'Sultan', value: 'Sultan' },
  { label: 'Thakur', value: 'Thakur' },
  { label: 'Thakurani', value: 'Thakurani' },
  { label: 'Lt. Col', value: 'Lt. Col' },
  { label: 'Prof. Dr.', value: 'Prof. Dr.' },
  { label: 'Vice Admiral', value: 'Vice Admiral' },
  { label: 'Datuk', value: 'Datuk' },
  { label: 'Padma Bhushan', value: 'Padma Bhushan' },
  { label: 'Padma Shri', value: 'Padma Shri' },
  { label: 'Padma Vibhushan', value: 'Padma Vibhushan' },
];

export const eMarketTabFilterOptions: {
  label: string;
  value: 'EMAIL' | 'WHATSAPP';
  moduleName: PermissionModuleNames;
}[] = [
  {
    label: 'Email',
    value: 'EMAIL',
    moduleName: PermissionModuleNames.EMAIL_CAMPAIGN,
  },
  {
    label: 'Whatsapp',
    value: 'WHATSAPP',
    moduleName: PermissionModuleNames.WHATSAPP_CAMPAIGN,
  },
];

<div
  class="wrapper p-field"
  [ngClass]="wrapperNgClasses"
  [style.fontSize]="fontSize"
>
  <label
    *ngIf="label"
    class="label"
    [ngClass]="{
      float__label: float,
      label__static: !float,
      'float-inside__label': floatInsideLabel
    }"
    [for]="controlName"
    >{{ label }}</label
  >
  <p-calendar
    class="input"
    [ngClass]="{
      input__static: label && alignment === 'vertical',
      input__float: float || floatInsideLabel,
      input__standard: variant === 'standard',
      input__outlined: variant === 'outlined',
      input__error: error,
      input__disabled: isDisabled,
      month__view: view === 'month'
    }"
    [inputId]="controlName"
    [(ngModel)]="dateValue"
    [selectOtherMonths]="true"
    (ngModelChange)="onDateChange($event)"
    [showTime]="enableTime"
    [showSeconds]="enableSeconds"
    [readonlyInput]="false"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [dateFormat]="dateFormat"
    [hourFormat]="hourFormat"
    [showButtonBar]="enableButtonBar"
    [monthNavigator]="true"
    [yearNavigator]="true"
    [yearRange]="yearRange"
    (onBlur)="markAsTouched()"
    [disabled]="isDisabled"
    [placeholder]="placeholder"
    [showIcon]="showIcon"
    [timeOnly]="isTimeOnly"
    [view]="view"
    [dateFormat]="dateFormat"
  ></p-calendar>
  <small *ngIf="error" class="p-error">{{ error }} </small>

  <!-- <i class="pi" [ngClass]="modIconNgClasses"></i> -->
</div>

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FormComponent } from '../form.components';
import { InputText } from 'primeng/inputtext';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'hospitality-bot-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent extends FormComponent implements AfterViewInit {
  @Input() isHideSpinners = false;
  @Input() maxLength: number;
  @Input() min: number;
  @Input() max: number;
  @Input() controlName: string;
  @Input() autofocus: boolean;

  strike = false;
  inputLength = 0;

  @Input() viewButton = false;
  @Input() buttonText = 'Save';

  @Output() onSaveText = new EventEmitter();

  constructor(
    public controlContainer: ControlContainer,
    public el: ElementRef
  ) {
    super(controlContainer);
  }

  ngOnInit(): void {
    this.initInputControl();
    this.listenForInputChanges();
    this.calculateInputLength(this.inputControl?.value);
  }

  listenForInputChanges() {
    this.inputControl?.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        //for every changes
        this.validateInput(value);
      });
  }

  calculateInputLength(value: any) {
    if (this.maxLength) {
      this.inputLength = value?.length ?? 0;
      this.subtitle = `${this.inputLength}/${this.maxLength}`;
    }
  }

  validateInput(inputValue: string) {
    if (inputValue?.trim()?.length <= 0) {
      this.inputControl.patchValue(null, { emitEvent: false });
      this.calculateInputLength(null);
    } else {
      this.calculateInputLength(inputValue);
    }
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      setTimeout(() => {
        const element = document.getElementById(
          this.controlName
        ) as HTMLInputElement;
        element.focus();
      }, 0);
    }
  }

  /**
   *
   * to disable value changes on scroll
   */
  disableValueChangesOnScroll(e) {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();
  }

  get getWrapperNgClasses() {
    return {
      ...this.wrapperNgClasses,
      'hide-spinner': this.isHideSpinners,
    };
  }

  get getInputClass() {
    return {
      ...this.inputNgClasses,
      input: true,
      'subtitle-padding': !!this.subtitle?.length,
      'view-cta-btn-padding': this.viewButton,
    };
  }

  handleKeyClick(event: KeyboardEvent) {
    this.handleKeyDown(event);
    this.handleEnterClick(event);
  }

  handleEnterClick(event: KeyboardEvent): void {
    // Check if the pressed key is Enter and the focus is on the current input field
    if (event.key === 'Enter' && event.target === event.currentTarget) {
      this.onSaveText.emit();
    }
  }
}

<div #tabFilter>
  <div class="tab-wrapper">
    <mat-tab-group
      [selectedIndex]="selectedIndex"
      (selectedTabChange)="onSelectedTabChange($event)"
      mat-stretch-tabs
      class="example-stretched-tabs mat-elevation-z4"
      click-stop-propagation
    >
      <mat-tab
        *ngFor="let tab of listItems; let index = index"
        [disabled]="tab.disabled"
      >
        <ng-template mat-tab-label>
          <ng-container *ngIf="tab?.iconSrc; else textLabel">
            <img
              src="{{ tab.iconSrc }}"
              [ngClass]="{ 'active-image': selectedIndex == index }"
            />
          </ng-container>

          <ng-template #textLabel>
            {{ tab.total >= 0 && isCountVisible ? tab.label : tab.label }}
            <!-- .concat(' (', tab.total, ')') -->
          </ng-template>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <ng-content></ng-content>
  </div>
</div>

<!-- NOTE: Alternate of the material-->
<!-- <div #tabFilter>
  <div
    *ngIf="listItems?.length"
    class="tab-wrapper tab-group__{{ view }}"
    [ngClass]="{
      'hidden-content': !isContent,
      'scrollable-tab': isScrollable
    }"
  >
    <p-tabView
      [activeIndex]="selectedIndex"
      (onChange)="onSelectedTabChange($event)"
      class="example-stretched-tabs"
      click-stop-propagation
    >
      <p-tabPanel
        *ngFor="let tab of listItems; let index = index"
        [disabled]="tab?.disabled"
      >
        <ng-template pTemplate="header">
          <div class="p-tabview-title">
            <i *ngIf="tab?.iconClass" [ngClass]="tab?.iconClass"></i>
            <img
              *ngIf="tab?.iconSrc"
              [src]="tab.iconSrc"
              [ngClass]="{ 'active-image': selectedIndex == index }"
            />
            <span *ngIf="tab?.label?.length">{{
              tab?.total >= 0
                ? tab.label.concat(' (', tab.total, ')')
                : tab.label
            }}</span>
          </div>
        </ng-template>
        <ng-content *ngIf="isContent" [select]="[tab.value]"></ng-content>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
 -->

<div
  class="tiered__wrapper"
  [style]="{
    'min-width': minWidth
  }"
>
  <div
    #btn
    [ngClass]="{
      'tiered-button': !splitButton,
      'split-button': splitButton
    }"
    [style.cursor]="disabled ? 'not-allowed' : 'pointer'"
    (click)="!splitButton && menu.toggle($event)"
  >
    <span
      class="label"
      [ngClass]="{ disabled: disabled }"
      (click)="!disabled && labelClickedAction()"
      >{{ label }}</span
    >
    <span
      class="icon"
      [ngClass]="{ disabled: isDisabledMenu }"
      (click)="splitButton && onToggle($event)"
      title="Expand"
      ><i [ngClass]="'pi pi-chevron-down'"></i
    ></span>
  </div>

  <p-tieredMenu
    #menu
    [model]="items"
    appendTo="body"
    [popup]="true"
  ></p-tieredMenu>
</div>

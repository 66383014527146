import * as moment from 'moment';

export function dateTimeWithFormat(
  value: any,
  formate: 'DD-MM-YYYY' | string = 'DD-MM-YYYY'
) {
  return moment(value).format(formate);
}

export function formatDateToCustomString(dateMS: number) {
  const date = new Date(dateMS);
  const months = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const dayOfWeek = date.toLocaleString('default', { weekday: 'short' });

  return `${months} ${day}, ${year} ${dayOfWeek}`;
}

export function getDateTimeInEpoch(data: {
  time: number;
  isHourseReset?: boolean;
}) {
  const time = new Date(data.time);
  if (data.isHourseReset) {
    time.setHours(0, 0, 0, 0);
  }

  return {
    time: time.getTime(),
  };
}

export function getCurrentYearStartDate() {
  // Get the current date
  const currentDate = new Date();

  // Set the month and day to January 1st of the current year
  const currentYearStartDate = new Date(currentDate.getFullYear(), 0, 1);
  return currentYearStartDate.getTime();
}

/**
 * @function formatEpochTime
 * @param epochTime - Epoch time in milliseconds
 * @returns A string representing the time in 12-hour format with AM/PM
 */
export function formatEpochTime(epochTime: number): string {
  return new Date(epochTime).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
}

/**
 * @function getFormattedDate
 * @param time - Epoch time in milliseconds
 * @returns A string representing the date and time in the format: "MMM DD, YYYY, hh:mm:ss AM/PM"
 */
export function getFormattedDateWithTime(
  time: number,
  isTimeOnly: boolean = false
) {
  if (!time) return; // Return undefined if time is not provided
  const currentDate = new Date(time);
  const monthAbbreviated = new Intl.DateTimeFormat('en-US', {
    month: 'short',
  }).format(currentDate);
  const date = currentDate.getDate();
  const year = currentDate.getFullYear();
  const hours = (currentDate.getHours() % 12 || 12).toString().padStart(2, '0'); // Convert to 12-hour format
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  const seconds = currentDate.getSeconds().toString().padStart(2, '0');
  const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM'; // Determine if it's AM or PM
  if (isTimeOnly) {
    return `${hours}:${minutes} ${ampm}`;
  }
  return `${monthAbbreviated} ${date}, ${year}, ${hours}:${minutes} ${ampm}`;
}

/**
 * @function getFormattedDate
 * @param time - Epoch time in milliseconds
 * @returns A string representing the date in the format: "MMM DD, YYYY"
 */
export function getFormattedDate(time: number) {
  if (!time) return; // Return undefined if time is not provided
  const currentDate = new Date(time);
  const monthAbbreviated = new Intl.DateTimeFormat('en-US', {
    month: 'short',
  }).format(currentDate);
  const date = currentDate.getDate();
  const year = currentDate.getFullYear();
  return `${monthAbbreviated} ${date}, ${year}`;
}

/**
 * Formats a duration from a specified time unit into a customizable human-readable string.
 *
 * @param {number} value - The duration to be formatted.
 * @param {Object} options - Configuration options for the formatting.
 * @param {string} options.fromUnit - The unit of the input value ('seconds', 'minutes', 'hours', or 'days').
 * @param {string[]} options.toUnits - An array of units to include in the output, in order of preference.
 * @param {Object} [options.labels] - Custom labels for each unit (singular and plural forms).
 * @param {string} [options.separator=' '] - The separator between different units in the output.
 * @returns {string|undefined} A formatted string representing the duration, or undefined if the input is falsy.
 *
 * @example
 * // returns "2h 30min"
 * formatDuration(150, { fromUnit: 'minutes', toUnits: ['hours', 'minutes'] });
 *
 * @example
 * // returns "1 day 6 hours"
 * formatDuration(30, { fromUnit: 'hours', toUnits: ['days', 'hours'], labels: { days: { singular: 'day', plural: 'days' } } });
 *
 * @example
 * // returns "1hr 30mins"
 * formatDuration(90, {
 *   fromUnit: 'minutes',
 *   toUnits: ['hours', 'minutes'],
 *   labels: { hours: { singular: 'hr', plural: 'hrs' }, minutes: { singular: 'min', plural: 'mins' } },
 *   separator: ' '
 * });
 */
export function formatDuration(
  value: number,
  options: {
    fromUnit: 'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'days';
    toUnits: Array<'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'days'>;
    labels?: {
      [key: string]: { singular: string; plural: string };
    };
    separator?: string;
    precision?: number;
  }
): string | undefined {
  if (!value) return undefined;

  const defaultLabels = {
    days: { singular: 'day', plural: 'days' },
    hours: { singular: 'hour', plural: 'hours' },
    minutes: { singular: 'minute', plural: 'minutes' },
    seconds: { singular: 'second', plural: 'seconds' },
    milliseconds: { singular: 'millisecond', plural: 'milliseconds' },
  };

  const labels = { ...defaultLabels, ...options.labels };
  const separator = options.separator || ' ';
  const precision = options.precision ?? 0;

  const conversionFactors = {
    days: 86400000,
    hours: 3600000,
    minutes: 60000,
    seconds: 1000,
    milliseconds: 1,
  };

  // Convert input to milliseconds
  let totalMilliseconds = value * conversionFactors[options.fromUnit];

  return (
    options.toUnits
      .reduce((result, unit, index, array) => {
        const unitValue = Math.floor(
          totalMilliseconds / conversionFactors[unit]
        );
        if (
          unitValue > 0 ||
          (index === array.length - 1 && result.length === 0)
        ) {
          const isLastUnit = index === array.length - 1;
          let formattedValue: number | string = unitValue;

          if (isLastUnit && precision > 0) {
            formattedValue = (
              totalMilliseconds / conversionFactors[unit]
            ).toFixed(precision);
          }

          const label =
            unitValue === 1 ? labels[unit].singular : labels[unit].plural;
          result.push(`${formattedValue} ${label}`);
          totalMilliseconds %= conversionFactors[unit];
        }
        return result;
      }, [] as string[])
      .join(separator) || undefined
  );
}

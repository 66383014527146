export const CURRENCIES: { [code: string]: (string | undefined)[] } = {
  AOA: [, 'Kz'],
  ARS: [, '$'],
  AUD: ['A$', '$'],
  BAM: [, 'KM'],
  BBD: [, '$'],
  BDT: [, '৳'],
  BMD: [, '$'],
  BND: [, '$'],
  BOB: [, 'Bs'],
  BRL: ['R$'],
  BSD: [, '$'],
  BWP: [, 'P'],
  BYN: [, 'р.'],
  BZD: [, '$'],
  CAD: ['CA$', '$'],
  CLP: [, '$'],
  CNY: ['CN¥', '¥'],
  COP: [, '$'],
  CRC: [, '₡'],
  CUC: [, '$'],
  CUP: [, '$'],
  CZK: [, 'Kč'],
  DKK: [, 'kr'],
  DOP: [, '$'],
  EGP: [, 'E£'],
  ESP: [, '₧'],
  EUR: ['€'],
  FJD: [, '$'],
  FKP: [, '£'],
  GBP: ['£'],
  GEL: [, '₾'],
  GIP: [, '£'],
  GNF: [, 'FG'],
  GTQ: [, 'Q'],
  GYD: [, '$'],
  HKD: ['HK$', '$'],
  HNL: [, 'L'],
  HRK: [, 'kn'],
  HUF: [, 'Ft'],
  IDR: [, 'Rp'],
  ILS: ['₪'],
  INR: ['₹'],
  ISK: [, 'kr'],
  JMD: [, '$'],
  JPY: ['¥'],
  KHR: [, '៛'],
  KMF: [, 'CF'],
  KPW: [, '₩'],
  KRW: ['₩'],
  KYD: [, '$'],
  KZT: [, '₸'],
  LAK: [, '₭'],
  LBP: [, 'L£'],
  LKR: [, 'Rs'],
  LRD: [, '$'],
  LTL: [, 'Lt'],
  LVL: [, 'Ls'],
  MGA: [, 'Ar'],
  MMK: [, 'K'],
  MNT: [, '₮'],
  MUR: [, 'Rs'],
  MXN: ['MX$', '$'],
  MYR: [, 'RM'],
  NAD: [, '$'],
  NGN: [, '₦'],
  NIO: [, 'C$'],
  NOK: [, 'kr'],
  NPR: [, 'Rs'],
  NZD: ['NZ$', '$'],
  PHP: [, '₱'],
  PKR: [, 'Rs'],
  PLN: [, 'zł'],
  PYG: [, '₲'],
  RON: [, 'lei'],
  RUB: [, '₽'],
  RUR: [, 'р.'],
  RWF: [, 'RF'],
  SBD: [, '$'],
  SEK: [, 'kr'],
  SGD: [, '$'],
  SHP: [, '£'],
  SRD: [, '$'],
  SSP: [, '£'],
  STD: [, 'Db'],
  SYP: [, '£'],
  THB: [, '฿'],
  TOP: [, 'T$'],
  TRY: [, '₺'],
  TTD: [, '$'],
  TWD: ['NT$', '$'],
  UAH: [, '₴'],
  USD: ['$'],
  UYU: [, '$'],
  VEF: [, 'Bs'],
  VND: ['₫'],
  XAF: ['FCFA'],
  XCD: ['EC$', '$'],
  XOF: ['CFA'],
  XPF: ['CFPF'],
  ZAR: [, 'R'],
  ZMW: [, 'ZK'],
};

export function findCurrencySymbol(code: string) {
  const currency = CURRENCIES[code] || {};
  const symbol = currency[0] || code;
  return currency[1] || symbol;
}

<div class="toggle-menu">
  <button
    #btn
    type="button"
    pButton
    icon="pi pi-ellipsis-v"
    (click)="menu.toggle($event)"
    [disabled]="disabled"
  ></button>
  <p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
</div>

<div class="toggle-wrapper" [ngClass]="{ disable: isDisabled }">
  <span *ngIf="preContent.length" class="pre-content">{{ preContent }}</span>
  <label class="toggle">
    <input
      type="checkbox"
      [checked]="isToggleOn"
      (change)="toggleSwitch()"
      [disabled]="isDisabled"
    />
    <span
      class="slider"
      [ngClass]="disableTurnOff ? 'disable' : ''"
      [ngStyle]="{
        'background-color': isToggleOn ? toggleOnColor : toggleOffColor
      }"
    ></span>
    <span
      class="labels"
      [ngClass]="isToggleOn ? 'labels__on' : 'labels__off'"
      >{{ isToggleOn ? toggleOnLabel : toggleOffLabel }}</span
    >
  </label>
</div>

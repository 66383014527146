<th id="select-all" class="select-box" *ngIf="showCheckbox">
  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
</th>
<th
  [id]="col.fields"
  [pSortableColumn]="col.isSortDisabled ? '' : col.sortField || col.field"
  *ngFor="let col of columns"
  [ngStyle]="{
    width: col.width ? col.width : null,
    'pointer-events': col.isHidden ? 'none' : 'auto'
  }"
>
  <div class="table-head" *ngIf="!col.isHidden">
    <div class="table-head__field">
      <ng-container [ngSwitch]="col?.type">
        <hospitality-bot-checkbox
          *ngSwitchCase="'checkbox'"
          [label]="col.header"
          [controlName]="col.field"
          [description]="col.description"
        >
        </hospitality-bot-checkbox>
        <div class="col-heading" *ngSwitchDefault>{{ col.header }}</div>
      </ng-container>

      <div class="col-sort" *ngIf="!col.isSortDisabled">
        <p-sortIcon [field]="col.sortField || col.field"></p-sortIcon>
      </div>
    </div>
    <ng-container *ngIf="showSearch">
      <div
        class="table-head__search"
        click-stop-propagation
        *ngIf="isSearchActive"
      >
        <input
          pInputText
          type="text"
          (input)="onSearch($event.target.value, col)"
          [placeholder]="col.placeholder ? col.placeholder : ''"
          [disabled]="col.isSearchDisabled"
          class="p-column-filter"
        />
      </div>
    </ng-container>
  </div>
</th>

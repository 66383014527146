<div
  class="wrapper"
  [ngClass]="{
    wrapper__red: offLabel
  }"
>
  <span
    *ngIf="offLabel"
    class="label label__off"
    [ngClass]="{
      disabled: inputControl.value
    }"
    >{{ offLabel }}</span
  >
  <div [formGroup]="controlContainer.control">
    <p-inputSwitch
      [attr.disabled]="isDisabled || null"
      [formControlName]="controlName"
      [disabled]="isDisabled"
    ></p-inputSwitch>
  </div>
  <span
    [ngClass]="{
      disabled: !inputControl.value
    }"
    *ngIf="onLabel"
    class="label label__on"
    >{{ onLabel }}</span
  >
</div>

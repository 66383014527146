<mat-form-field class="example-chip-list width-100">
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let item of items"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(item)"
    >
      {{ item }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      placeholder=""
      #itemInput
      [formControl]="itemCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filtereditems | async" [value]="item">
      {{ item }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

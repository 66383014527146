import { Option } from '../types/form.type';

export function findOption<T extends Option>(
  option: Array<T>,
  targetId: string
): T {
  return option.find(
    (option) => option?.id === targetId || option?.value === targetId
  );
}

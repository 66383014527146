<div class="wrapper p-field" [formGroup]="controlContainer.control">
  <label *ngIf="label" class="label label__static" [for]="postControlName">{{
    label
  }}</label>
  <div class="main" [ngClass]="{ 'no-margin': !label }" [id]="postControlName">
    <ng-container [ngSwitch]="preFieldType">
      <hospitality-bot-input
        *ngSwitchCase="'input'"
        class="input pre-input"
        [ngClass]="{
          dashed: layout === 'dashed',
          primary: layout === 'pre-main',
          secondary: layout === 'post-main'
        }"
        [min]="preMin"
        [controlName]="preControlName"
        [props]="getProps('pre')"
        [disabled]="inputDisabled.pre || isDisabled"
      ></hospitality-bot-input>
      <hospitality-bot-select
        *ngSwitchCase="'select'"
        class="input slect pre-input"
        [ngClass]="{
          dashed: layout === 'dashed',
          primary: layout === 'pre-main',
          secondary: layout === 'post-main'
        }"
        [controlName]="preControlName"
        [props]="getProps('pre')"
        [disabled]="inputDisabled.pre || isDisabled"
        [options]="showFilteredOptions ? filterPreOptions : preOptions"
        [stopEmission]="stopEmission"
      ></hospitality-bot-select>
      <hospitality-bot-auto-complete
        *ngSwitchCase="'autocomplete'"
        [controlName]="preControlName"
        class="input pre-input"
        [ngClass]="{
          dashed: layout === 'dashed',
          main: layout === 'pre-main',
          secondary: layout === 'post-main'
        }"
        [loading]="loading"
        [disabled]="inputDisabled.pre || isDisabled"
        [settings]="{
          multiple: false,
          options: preOptions,
          isAsync: false
        }"
      >
      </hospitality-bot-auto-complete>
    </ng-container>

    <div
      *ngIf="dashHidden != true"
      class="disabled"
      [ngClass]="{
        hypen: layout === 'dashed'
      }"
    ></div>

    <ng-container [ngSwitch]="postFieldType">
      <hospitality-bot-input
        *ngSwitchCase="'input'"
        class="input post-input"
        [min]="postMin"
        [ngClass]="{
          dashed: layout === 'dashed',
          secondary: layout === 'pre-main',
          main: layout === 'post-main'
        }"
        [controlName]="postControlName"
        [props]="getProps('post')"
        [disabled]="inputDisabled.post || isDisabled"
      ></hospitality-bot-input>
      <hospitality-bot-select
        *ngSwitchCase="'select'"
        class="input post-input"
        [ngClass]="{
          dashed: layout === 'dashed',
          secondary: layout === 'pre-main',
          main: layout === 'post-main'
        }"
        [controlName]="postControlName"
        [props]="getProps('post')"
        [options]="showFilteredOptions ? filterPostOptions : postOptions"
        [disabled]="inputDisabled.post || isDisabled"
      ></hospitality-bot-select>
      <hospitality-bot-auto-complete
        *ngSwitchCase="'autocomplete'"
        [controlName]="postControlName"
        class="input auto-compelet"
        [ngClass]="{
          dashed: layout === 'dashed',
          secondary: layout === 'pre-main',
          main: layout === 'post-main'
        }"
        [loading]="loading"
        [disabled]="inputDisabled.post || isDisabled"
        [settings]="{
          multiple: false,
          options: postOptions,
          isAsync: false
        }"
      >
      </hospitality-bot-auto-complete>
    </ng-container>
  </div>
</div>
<ng-content></ng-content>

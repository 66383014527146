<div>
  <div class="row">
    <!-- Here header will come -->
  </div>
  <form [formGroup]="loginForm">
    <div class="card">
      <h4 class="card--heading">{{ 'login_text' | translate }}</h4>
      <h3 class="card--quote">{{ 'welcome_text' | translate }}</h3>
      <div class="card--social-btns">
        <!-- Social buttons will come here -->
      </div>
      <div class="card-form">
        <div class="form-row">
          <mat-form-field>
            <input
              type="text"
              matInput
              formControlName="email"
              placeholder="{{ 'placeholder.email' | translate }}"
            />
            <mat-error
              *ngIf="
                loginForm.get('email').touched &&
                loginForm.get('email').hasError('required')
              "
            >
              {{ 'validation.email_required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                loginForm.get('email').touched &&
                loginForm.get('email').hasError('pattern')
              "
            >
              {{ 'validation.validate_email' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field>
            <input
              [type]="!isPasswordVisible ? 'password' : 'text'"
              autocomplete="current-password"
              id="current-password"
              matInput
              formControlName="password"
              placeholder="{{ 'placeholder.password' | translate }}"
            />
            <button
              mat-icon-button
              matSuffix
              type="button"
              (click)="isPasswordVisible = !isPasswordVisible"
            >
              <mat-icon>{{
                !isPasswordVisible ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </button>
            <mat-error
              *ngIf="
                loginForm.get('password').touched &&
                loginForm.get('password').hasError('required')
              "
            >
              {{ 'validation.password_required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                loginForm.get('password').touched &&
                (loginForm.get('password').hasError('minlength') ||
                  loginForm.get('password').hasError('maxlength'))
              "
            >
              {{ 'validation.validate_password' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="forgot__block">
        <a class="forgot--password" (click)="navigateToRequestPassword()">{{
          'button.forgot_password' | translate
        }}</a>
      </div>
      <div class="card-footer {{ isSigningIn && 'click-disable' }}">
        <button type="submit" class="login" mat-button (click)="login()">
          <i *ngIf="isSigningIn" class="fa fa-spinner fa-spin"></i>
          {{ 'button.login' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>

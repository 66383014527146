<div
  class="wrapper"
  [style.fontSize]="fontSize"
  [ngClass]="wrapperNgClasses"
  [ngStyle]="{
    'pointer-events':
      inputControl.status === 'DISABLED' || isDisabled ? 'none' : 'auto',
    opacity: inputControl.status === 'DISABLED' || isDisabled ? 0.6 : 1
  }"
>
  <label *ngIf="label" class="label label__static">{{ label }}</label>
  <div class="input" [ngClass]="inputNgClasses">
    <div
      class="option-btn"
      *ngFor="let option of defaultOptions; let i = index"
      (click)="!option.isDisabled && handleClick(option.value, i)"
      [ngClass]="{
        'option-btn__checkbox': fieldType === 'checkbox',
        'option-btn__radio': fieldType === 'radio',
        'option-selected__radio': fieldType === 'radio' && option.isSelected,
        'option-selected__checkbox':
          fieldType === 'checkbox' && option.isSelected,
        'option-btn__disabled': option.isDisabled
      }"
    >
      <span>
        {{ option.label }}
      </span>
      <div class="checkbox" *ngIf="fieldType === 'checkbox'">
        <img
          *ngIf="option.isSelected"
          class="checkbox__img"
          src="assets/svg/checkboxSelect.svg"
          alt="select"
        />
      </div>
    </div>
  </div>
  <small *ngIf="error" class="p-error">{{ error }}</small>
</div>

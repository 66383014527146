<input
  #fileInput
  type="file"
  [accept]="
    baseType == 'image' ? 'image/*' : baseType == 'video' ? 'video/*' : ''
  "
  (click)="fileInput.value = null"
  [disabled]="isDisable"
  (change)="onSelectFile($event)"
  class="input-upload"
  style="display: none;"
/>

<div class="wrapper">
  <div class="label" *ngIf="label != ''">{{ label }}</div>
  <div *ngIf="unit !== 1" class="description">{{ description }}</div>

  <div class="upload-boxes">
    <div
      [ngClass]="{ 'd-flex': unit == 1 }"
      *ngFor="let url of fileUrls; let i = index"
    >
      <div class="image-wrapper">
        <img
          file-upload-css
          loading="'lazy'"
          [src]="baseType == 'image' ? url : thumbUrl"
          class="box"
          [style.display]="url != defaultImage || thumbUrl ? 'block' : 'none'"
          alt="uploaded image"
        />
        <div class="box" *ngIf="url == defaultImage && !thumbUrl">
          <img class="upload-icon" [src]="url" alt="upload-icon" />
        </div>
        <span
          class="banner"
          *ngIf="featureValueIndex?.includes(i) && isFeatureView"
        >
          Featured Image</span
        >
      </div>

      <div
        [style.display]="unit == 1 ? 'flex' : ''"
        class="right-content"
        [ngClass]="{ disable: isDisable }"
      >
        <div *ngIf="unit == 1" class="content">{{ description }}</div>
        <div [style.width]="unit == 1 ? '302px' : '200px'" class="hint">
          {{ hint }}
        </div>
        <div
          class="checkbox"
          *ngIf="isFeatureView"
          [ngClass]="{
            disable:
              !this.featureValueIndex?.includes(i) && this.exceedFeatureLimit
          }"
        >
          <!-- <input
            #checkbox
            [disabled]="
              !this.featureValueIndex?.includes(i) && this.exceedFeatureLimit
            "
            type="checkbox"
            [checked]="featureValueIndex?.includes(i)"
            (change)="processCheckboxChange($event, i)"
          />
          <label (click)="checkbox.click()" for="checkbox">
            Make Featured Image</label -->

          <hospitality-bot-checkbox
            class="checkbox-control"
            [isDisabled]="
              !this.featureValueIndex?.includes(i) && this.exceedFeatureLimit
            "
            [value]="featureValueIndex?.includes(i)"
            (change)="processCheckboxChange($event, i)"
            label="Make Featured Image"
          >
          </hospitality-bot-checkbox>
          <br />
        </div>
        <div
          class="upload-button"
          [style.margin-top]="unit == 1 ? 'auto' : ''"
          [ngStyle]="{
            'margin-top': unit == 1 ? 'auto' : '',
            'justify-content':
              unit !== 1 && url !== defaultImage
                ? 'flex-start'
                : 'space-between'
          }"
        >
          <hospitality-bot-button
            label="Upload"
            (click)="uploadFiles(i)"
            *ngIf="url == defaultImage && !thumbUrl"
            variant="outlined"
          ></hospitality-bot-button>
          <hospitality-bot-button
            label="Change"
            [disabled]="isDisable"
            (click)="uploadFiles(i)"
            *ngIf="url !== defaultImage || thumbUrl"
            variant="outlined"
          ></hospitality-bot-button>

          <div *ngIf="url !== defaultImage || thumbUrl">
            <hospitality-bot-button
              [disabled]="isDisable"
              [label]="
                'Remove ' +
                this.baseType.charAt(0).toUpperCase() +
                baseType.slice(1)
              "
              (click)="removeImage(i)"
              *ngIf="unit == 1"
              variant="text"
              severity="reset"
            ></hospitality-bot-button>

            <div>
              <img
                class="delete-icon"
                *ngIf="unit !== 1"
                (click)="removeImage(i)"
                src="assets/images/delete-icon.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p
  class="validation-error"
  *ngIf="control?.errors?.required && control?.touched"
>
  {{ validationErrMsg }}
</p>
<hospitality-bot-button
  class="create-btn"
  label="+Add More Images"
  (click)="addMoreImages()"
  *ngIf="isMultiple"
  variant="outlined"
></hospitality-bot-button>

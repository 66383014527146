<div class="modal-header">
  <div class="header">
    <p class="heading">
      {{ tableName }}
    </p>
  </div>
  <div class="icon-wrapper">
    <div (click)="closeModal()" class="close">
      <i class="fa fa-times" aria-hidden="true"></i>
    </div>
    <div class="action-wrapper">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<div class="checkbox-wrapper" (click)="toggleSelectAll()">
  <input
    type="checkbox"
    (change)="toggleSelectAll()"
    class="d-none"
    [disabled]="isDisabled"
  />
  <div class="checkbox">
    <img
      *ngIf="value"
      class="checkbox__img"
      src="assets/svg/checkboxSelect.svg"
      alt="select"
    />
  </div>
  <div class="label-wrapper">
    <span *ngIf="label" class="checkbox-label">{{ label }}</span>
    <span *ngIf="description" class="checkbox-description">{{
      description
    }}</span>
  </div>
</div>

<div class="" [hidden]="true">{{ _value }}</div>

<button
  [type]="type"
  (click)="handleClick($event)"
  [disabled]="disabled || isLoading"
  class="button"
  [ngStyle]="{
    'min-width': minWidth,
    'height': height
  }"
  [ngClass]="{
    button__text: variant === 'text',
    button__contained: variant === 'contained',
    button__outlined: variant === 'outlined',
    color__primary: severity === 'primary',
    color__secondary: severity === 'secondary',
    color__reset: severity === 'reset',
    button__disabled: disabled || isLoading,
    button__img: icon
  }"
>
  <i *ngIf="piIcon" [class]="piIcon"></i>
  <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i>
  <img [src]="icon" alt="buttonImage" *ngIf="icon" /> {{ label }}
</button>

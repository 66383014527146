<i
  #icon
  class="pi pi-info-circle icon"
  (mouseenter)="handleOnMouseEnter($event)"
  style="font-size: 1.8rem; color: blue; padding: 8px 0;"
></i>

<p-overlayPanel appendTo="body" class="overlay" #overlay>
  <div class="info-container">
    <div class="sub-container">
      <div class="title-container">{{ title }}</div>
      <div class="room-status">
        <div class="status" *ngFor="let status of roomStatus">
          <span
            class="circle"
            [ngStyle]="{ backgroundColor: status.color }"
          ></span>
          <span class="label">{{ status.name }}</span>
        </div>
      </div>
    </div>
    <div class="sub-container">
      <div class="title-container title-1">
        <span class="title">Booking Indicators</span>
      </div>
      <div class="room-status">
        <div class="status" *ngFor="let status of bookingIndicator">
          <img class="icon" [src]="status.icon" alt="icon" />
          <span class="label">{{ status.name }}</span>
        </div>
      </div>
    </div>
    <div class="sub-container">
      <div class="title-container title-1">Room Features</div>
      <div class="room-status">
        <div class="status" *ngFor="let feature of features">
          <img class="icon" [src]="feature.icon" alt="icon" />
          <span class="label">{{ feature.name }}</span>
        </div>
      </div>
    </div>
  </div>
</p-overlayPanel>

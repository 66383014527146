<div class="wrapper__container">
  <div
    *ngFor="let year of years"
    class="wrapper"
    [ngStyle]="{
      width: calendarWidth
    }"
  >
    <div class="wrapper__main">
      <!-- LEFT SECTION -->
      <div class="left bdr-left">
        <div class="left__main bg-header bdr-rgt">
          <div
            class="main-header main-header__left"
            [ngStyle]="{
              height: height
            }"
          >
            <div
              class="date__wrapper bdr-btm"
              [ngStyle]="{
                fontSize: fontSize
              }"
            >
              {{ year }}
            </div>
          </div>
          <div class="main-section">
            <div
              class="row_info bdr-btm"
              [ngStyle]="{
                height: height,
                fontSize: fontSize
              }"
              *ngFor="let item of rowsInfo"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>

      <!-- RIGHT SECTION -->
      <div class="right bdr-top bdr-rgt">
        <div class="right__main">
          <div
            class="main-header main-header__right"
            [ngStyle]="{
              height: height
            }"
          >
            <div class="date__wrapper date__header">
              <div
                class="col_info bg-header bdr-btm-rgt"
                [ngStyle]="{
                  minWidth: height,
                  fontSize: fontSize
                }"
                *ngFor="let item of colsInfo"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="main-section">
            <div *ngFor="let gridRow of gridInfo[year]" class="grid__row">
              <div
                *ngFor="let gridRowData of gridRow; let gridRowDataIdx = index"
                class="grid__data bdr-btm-rgt bg-data"
                [ngStyle]="getStyles(gridRowData.value)"
                (click)="onGridClick(gridRowData.value, gridRowDataIdx)"
                (mouseenter)="openOverlayPanel($event, gridRowData.value)"
                (mouseleave)="closeOverlayPanel($event, gridRowData.value)"
                [pTooltip]="tooltip ? tooltip : undefined"
                showDelay="500"
                tooltipPosition="top"
              >
                <div
                  [ngStyle]="getMarkedDatesStyle(gridRowData.value)"
                  class="grid__marked"
                >
                  {{ gridRowData.inactive ? '' : gridRowData.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="wrapper"
  [ngClass]="wrapperNgClasses"
  [formGroup]="controlContainer.control"
>
  <label *ngIf="label" class="label label__static">{{ label }}</label>
  <div class="input">
    <div
      *ngIf="isLabel"
      class="state-label"
      [ngClass]="{ 'highlighted-label': isHighlightedLabel && !value }"
    >
      {{ offLabel }}
    </div>
    <p-inputSwitch
      [inputId]="controlName"
      [formControlName]="controlName"
      [disabled]="isDisabled"
    ></p-inputSwitch>
    <div
      *ngIf="isLabel"
      class="state-label"
      [ngClass]="{ 'highlighted-label': isHighlightedLabel && value }"
    >
      {{ onLabel }}
    </div>
  </div>
</div>

<div class="detail--container" [formGroup]="modelForm">
  <div class="header">
    <div class="header__title">
      <mat-icon class="alert">warning</mat-icon>
      <div>{{ heading }}</div>

      <div class="date" *ngIf="isDate">
        <span *ngIf="fromDate; else empty">{{
          fromDate | date: 'dd/MM/yyyy'
        }}</span>
        to
        <span *ngIf="toDate; else empty">{{
          toDate | date: 'dd/MM/yyyy'
        }}</span>
      </div>
    </div>
    <div (click)="close()" class="header__close">
      <i class="fa fa-times" aria-hidden="true"></i>
    </div>
  </div>
  <div class="body">
    <div class="title" *ngIf="title?.length">{{ title }}</div>
    <div class="descriptions">
      <div class="text" *ngFor="let text of descriptions">
        {{ text }}
      </div>
    </div>
    <div class="reservation-input" *ngIf="isReservation">
      <div class="amount">
        <hospitality-bot-input
          class="one-third-width"
          label="Amount"
          controlName="chargedAmount"
          [props]="{
            placeholder: 'Enter'
          }"
          [disabled]="!this.modelForm.get('chargeable').value"
        ></hospitality-bot-input>
        <ul class="charges-info">
          <li>Exclusive of tax</li>
        </ul>
      </div>
      <hospitality-bot-toggle-switch
        class="status"
        controlName="chargeable"
        onLabel="Chargeable"
        offLabel="Non-chargeable"
      ></hospitality-bot-toggle-switch>
    </div>
    <hospitality-bot-text-area
      *ngIf="isRemarks"
      [ngStyle]="{ 'margin-top': isReservation ? '20px' : '0px' }"
      controlName="remarks"
      label="Remarks"
      [props]="{
        placeholder: 'Enter'
      }"
    ></hospitality-bot-text-area>

    <ng-content></ng-content>
    <div class="actions">
      <ng-container *ngIf="!!actions?.length; else defaultCTA">
        <hospitality-bot-button
          *ngFor="let action of actions"
          [label]="action.label"
          (onClick)="onButtonClick(action)"
          [variant]="action.variant"
        ></hospitality-bot-button>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #defaultCTA>
  <hospitality-bot-button
    [label]="defaultAction.label"
    (onClick)="defaultAction.onClick()"
    [variant]="defaultAction.variant"
  ></hospitality-bot-button>
</ng-template>

<ng-template #empty>--/--/----</ng-template>

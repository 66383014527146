<hospitality-bot-prefix-field
  *ngIf="!isPriceControl"
  [class]="className"
  [options]="currencies"
  [label]="originalPriceLabel"
  [preControlName]="currency"
  [postControlName]="originalPrice"
  [defaultProps]="{
    post: { type: 'number', placeholder: 'Enter Base price' }
  }"
  [props]="{ errorMessages: errorMessages }"
></hospitality-bot-prefix-field>
<hospitality-bot-multiple-prefix-input
  *ngIf="isPriceControl"
  [class]="className"
  [controls]="{
    currencyControlName: currency,
    preCountControlName: originalPrice,
    postCountControlName: price
  }"
  [labels]="{
    mainLabel: originalPriceLabel,
    preCountLabel: preCountLabel,
    postCountLabel: postCountLabel
  }"
  [currencyOptions]="currencies"
  [defaultProps]="{
    post: {
      type: 'number',
      float: true,
      errorMessages: {
        required: 'This is a required field.',
        min: 'Value must be greater than 0.'
      },
      isPriceField: true
    }
  }"
></hospitality-bot-multiple-prefix-input>
<hospitality-bot-prefix-field
  [class]="className"
  [options]="discountTypes"
  [label]="discountLabel"
  [preControlName]="discountType"
  [postControlName]="discountValue"
  [defaultProps]="{
    post: { type: 'number', placeholder: 'Enter Discount' }
  }"
  [props]="{ errorMessages: errorMessages }"
>
</hospitality-bot-prefix-field>
<hospitality-bot-prefix-field
  [class]="className"
  [options]="currencies"
  [label]="discountedPriceLabel"
  [preControlName]="discountedPriceCurrency"
  [postControlName]="discountedPrice"
  [defaultProps]="{
    post: { type: 'number', placeholder: 'Enter price' }
  }"
  [disabled]="true"
>
</hospitality-bot-prefix-field>
<ng-content></ng-content>

<div
  [ngClass]="{ 'only-routes': onlyNavRoutes }"
  class="nav-header-wrapper"
  #header
>
  <div
    *ngIf="isStickyTitle && isScrolledUp"
    [ngStyle]="{ 'height.px': extraGap }"
  ></div>
  <div
    class="nav-wrapper"
    *ngIf="routes.length"
    [ngClass]="{
      'nav-wrapper__sticky': isScrolledUp && !isStickyTitle && !onlyNavRoutes
    }"
  >
    <div class="link" *ngFor="let route of routes; let idx = index">
      <span
        [disabled]="route.isDisabled || idx === routes.length - 1"
        [routerLink]="route.link"
        (click)="handleRouteIdx(idx)"
        [queryParamsHandling]="'preserve'"
        [ngClass]="{
          link__disabled:
            route.link === './' ||
            route.isDisabled ||
            idx === routes.length - 1,
          link__active:
            route.link !== './' &&
            !route.isDisabled &&
            idx !== routes.length - 1,
          disabled: route.isDisabled || idx === routes.length - 1
        }"
      >
        {{ route.label }}
      </span>
      <i *ngIf="idx < routes.length - 1" class="pi pi-angle-right"></i>
    </div>
    <div class="nav-actions">
      <ng-content></ng-content>
    </div>
  </div>
  <div
    *ngIf="!onlyNavRoutes"
    class="header__wrapper"
    [ngClass]="{
      'header__wrapper__sticky-sub-title': isScrolledUp && !isStickyTitle,
      'header__wrapper__sticky-title': isScrolledUp && isStickyTitle,
      header__wrapper__sticky: isStickyTitle
    }"
  >
    <header class="header">
      <i *ngIf="isBack" (click)="back()" class="pi pi-arrow-left"></i>
      <div class="label">{{ heading }}</div>
    </header>
    <div class="header__date" *ngIf="draftDate">
      {{ dateTitle || 'Drafted on' }} {{ draftDate }}
    </div>
  </div>
  <div *ngIf="isSpaceNeeded" [ngStyle]="{ 'height.px': extraSpace }"></div>
</div>

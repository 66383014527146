<div class="wrapper">
  <div class="header">
    <div>
      <div class="heading" *ngIf="label">{{ label }}</div>
      <div class="sub-heading" *ngIf="description">
        {{ description }}
      </div>
    </div>
    <div class="btn-wrapper" *ngIf="!fullView && !noMoreData">
      <ng-content></ng-content>

      <hospitality-bot-button
        *ngIf="options.length"
        label="View All"
        (onClick)="viewAll.emit()"
        variant="outlined"
      ></hospitality-bot-button>
    </div>
  </div>
  <div
    class="item-boxes"
    [ngClass]="{ expandedView: fullView }"
    infiniteScroll
    (scrolled)="loadMore()"
    [toggleScrollListener]="noMoreData"
    *ngIf="!!options.length; else emptyTable"
  >
    <ng-container *ngFor="let item of options; index as i">
      <div class="item-box" (click)="selectItems(i)">
        <div class="icon-wrapper">
          <img src="assets/svg/tick.svg" *ngIf="item.checked" alt="checked" />
        </div>
        <div
          [ngClass]="{
            'icon-type': type === 'icon',
            'image-type': type === 'image',
            selected: item.checked
          }"
        >
          <img
            [src]="item.imageUrl"
            class="show-image"
            *ngIf="options.length"
            [alt]="item.imageUrl"
            appImage
          />
        </div>
        <div class="text">
          <span>{{ item.name }}</span>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #emptyTable>
    <hospitality-bot-empty-table
      [height]="fullView ? '51vh' : 'fit-content'"
      [isLoading]="loading"
      [content]="noRecordsAction"
      (action)="onEmptyViewAction.emit()"
    >
    </hospitality-bot-empty-table>
  </ng-template>

  <p
    class="validation-error"
    *ngIf="control?.errors?.required && control?.touched"
  >
    {{ validationErrMsg }}
  </p>
</div>

import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DateService {
  static defaultTimeZone: string = '+5:30';
  static UTC_OFFSET: number = 5 * 60 + 30; // UTC +5:30

  private constructor() {
    // DateService.initTimeZoneOffset();
  }

  static initTimeZoneOffset(offset?: string) {
    DateService.UTC_OFFSET = DateService.offsetToMinutes(
      offset ?? DateService.defaultTimeZone
    );
  }

  static convertDateToTimestamp(
    inputDate,
    timezone = DateService.defaultTimeZone
  ) {
    return moment(inputDate).utcOffset(timezone).unix();
  }

  static convertTimestampToDate(
    inputTimeStamp,
    format?,
    timezone = DateService.defaultTimeZone
  ) {
    if (format) {
      return moment(inputTimeStamp).utcOffset(timezone).format(format);
    }
    return moment(inputTimeStamp).utcOffset(timezone).format('DD-MM-YYYY');
  }

  static currentDate(format?) {
    return DateService.convertTimestampToDate(moment.now(), format);
  }

  static getCurrentDateString(timezone = DateService.defaultTimeZone) {
    return moment().utcOffset(timezone).format();
  }

  static getCurrentDateWithFormat(
    format = 'DD-MM-YYYY',
    timezone = DateService.defaultTimeZone
  ) {
    return moment().utcOffset(timezone).format(format);
  }

  static getDateDifference(
    date1,
    date2,
    timezone = DateService.defaultTimeZone
  ) {
    return moment(date1).utcOffset(timezone).diff(moment(date2), 'days');
  }

  static getMonthDifference(
    date1,
    date2,
    timezone = DateService.defaultTimeZone
  ) {
    return moment(date1).utcOffset(timezone).diff(moment(date2), 'months');
  }

  static getMonthFromDate(timestamp, timezone = DateService.defaultTimeZone) {
    return moment
      .unix(timestamp / 1000)
      .utcOffset(timezone)
      .month();
  }

  static getYearFromDate(timestamp, timezone = DateService.defaultTimeZone) {
    return moment
      .unix(timestamp / 1000)
      .utcOffset(timezone)
      .year();
  }

  static getCurrentTimeZone() {
    return moment().format('Z');
  }

  static getDateFromTimeStamp(
    inputTimeStamp,
    format = 'DD-MM-YYYY',
    timezone = DateService.defaultTimeZone
  ) {
    return moment(inputTimeStamp).utcOffset(timezone).format(format);
  }

  static sortObjArrayByTimeStamp(arr, field, order = 'asc') {
    if (order === 'asc') {
      return arr?.sort((a, b) => (a[field] > b[field] ? 1 : -1));
    }
    return arr?.sort((a, b) => (a[field] < b[field] ? 1 : -1));
  }

  convertTimestampToLabels(type, timestamp, timezone, format?, toDate?) {
    let returnData = '';
    if (type === 'year') {
      returnData = timestamp;
    } else if (type === 'month') {
      returnData = moment(+timestamp)
        .utcOffset(timezone)
        .format(format || 'MMM YYYY');
    } else if (type === 'date') {
      returnData = moment(+timestamp)
        .utcOffset(timezone)
        .format(format || 'DD MMM');
    } else if (type === 'week') {
      let difference = DateService.getDateDifference(
        +toDate,
        +timestamp,
        timezone
      );
      difference = difference >= 0 && difference < 6 ? difference : 6;
      let monthDiff =
        DateService.getMonthFromDate(
          moment(+timestamp).utcOffset(timezone),
          timezone
        ) ===
        DateService.getMonthFromDate(
          moment(+timestamp)
            .utcOffset(timezone)
            .add(6, 'days'),
          timezone
        );
      returnData = difference
        ? moment(+timestamp)
            .utcOffset(timezone)
            .format(monthDiff ? 'D' : format || 'D MMM') +
          '-' +
          moment(+timestamp)
            .utcOffset(timezone)
            .add(difference, 'days')
            .format(format || 'DD MMM')
        : moment(+timestamp)
            .utcOffset(timezone)
            .format(format || 'D MMM');
    } else {
      returnData = `${timestamp > 12 ? timestamp - 12 : timestamp}:00 ${
        timestamp > 11 ? 'PM' : 'AM'
      }`;
    }
    return returnData;
  }

  getCalendarType(startDate, endDate, timezone): string {
    const dateDiff = DateService.getDateDifference(
      startDate,
      endDate,
      timezone
    );
    const monthDiff = DateService.getMonthDifference(
      startDate,
      endDate,
      timezone
    );
    if (dateDiff === 0) {
      return 'day';
    } else if (dateDiff > 0 && dateDiff <= 14) {
      return 'date';
    } else if (dateDiff > 14 && dateDiff <= 98) {
      return 'week';
    } else if (dateDiff > 98 && monthDiff <= 14) {
      return 'month';
    } else {
      return 'year';
    }
  }

  getCurrentTimeStamp(timezone = DateService.defaultTimeZone) {
    return moment().utcOffset(timezone).unix() * 1000;
  }

  static getDateMDY(date: number) {
    return new Date(date)?.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  static getTimeInHMSZ(value: number, formate: 'millisecond' | 'second') {
    value = formate == 'millisecond' ? value : value * 1000;
    const date = new Date(value);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
  }

  static offsetToMinutes(offset: string = DateService.defaultTimeZone) {
    // Remove any leading '+' or '-' sign
    const cleanedOffset = offset.replace(/^[+-]/, '');

    // Split the offset into hours and minutes
    const [hours, minutes] = cleanedOffset.split(':').map(Number);

    // Calculate total minutes
    const totalMinutes = hours * 60 + minutes;

    const value = offset.startsWith('-') ? -totalMinutes : totalMinutes;

    // Return the result with the sign of the original offset
    return value;
  }

  /**
   * Converts a date to the entity's timezone based on the UTC offset in minutes
   * @param date Date object or timestamp to convert
   * @param offsetMinutes UTC offset in minutes, defaults to the value of DateService.UTC_OFFSET
   * @returns A new Date object in the entity's timezone
   */
  static toEntityTime(
    date: string | number,
    offsetMinutes = DateService.UTC_OFFSET
  ) {
    // Create a new Date object based on the input date
    const localDate = new Date(date);
    // Calculate the time in milliseconds that corresponds to the desired offset
    const offsetMs = offsetMinutes * 60 * 1000;
    // Create a new Date object in UTC
    const utcDate = new Date(
      localDate.getTime() + localDate.getTimezoneOffset() * 60 * 1000
    );
    // Adjust for the desired offset
    return new Date(utcDate.getTime() + offsetMs);
  }

  /**
   * Reverses the offset applied by toEntityTime
   * @param convertedDate Date object or timestamp with the offset already applied
   * @param offsetMinutes UTC offset in minutes, defaults to the value of DateService.UTC_OFFSET
   * @returns A new Date object with the offset reversed
   */
  static reverseOffset(
    convertedDate: string | number,
    offsetMinutes = DateService.UTC_OFFSET
  ) {
    const convertedDateObj = new Date(convertedDate);
    const offsetMs = offsetMinutes * 60 * 1000;
    const utcDate = new Date(convertedDateObj.getTime() - offsetMs);
    return new Date(
      utcDate.getTime() - convertedDateObj.getTimezoneOffset() * 60 * 1000
    );
  }

  /**
   * Returns the current date in the entity timezone as a Date object.
   *
   * The returned date is adjusted to take into account the user's timezone offset.
   * @returns {Date} The current date in the user's timezone.
   */
  static getCurrentDate(): Date {
    return DateService.toEntityTime(new Date().getTime());
  }

  /**
   * Returns the epoch of the next day, given the current epoch.
   * @param currentEpoch The current epoch time in milliseconds.
   * @returns The epoch of the next day.
   */
  static getNextDateEpoch(currentEpoch: number) {
    return currentEpoch + 24 * 60 * 60 * 1000;
  }
}

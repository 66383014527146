<p-splitButton
  [ngClass]="{
    button__contained: variant === 'contained',
    button__outlined: variant === 'outlined',
    button__disabled: disabled || isLoading
  }"
  [label]="label"
  (onClick)="onClick.emit($event)"
  [model]="splitOptions"
  [icon]="icon"
  [disabled]="disabled || isLoading"
  appendTo="body"
></p-splitButton>

<input
  #fileInput
  type="file"
  (click)="fileInput.value = null"
  [disabled]="isDisable"
  (change)="onSelectFile($event)"
  class="input-upload"
  style="display: none;"
/>

<div
  class="wrapper"
  [ngClass]="wrapperNgClasses"
  [formGroup]="controlContainer.control"
>
  <label
    *ngIf="label && !float"
    class="label label__static"
    [for]="controlName"
    >{{ label }}</label
  >
  <div (click)="onAddAttachment()">
    <p-multiSelect
      #multiSelect
      class="input"
      [ngClass]="inputNgClasses"
      [disabled]="isDisabled || null"
      [formControlName]="controlName"
      [inputId]="controlName"
      [options]="menuOptions"
      [dropdownIcon]="'fas fa-paperclip'"
    >
      <ng-template let-value pTemplate="selectedItems">
        <div class="chips">
          <div class="chip" [click-stop-propagation] *ngFor="let val of value">
            <div>{{ dictionary[val] }}</div>
            <i
              class="pi pi-times"
              (click)="handleClear(val); $event.stopPropagation()"
            ></i>
          </div>
        </div>
      </ng-template>
    </p-multiSelect>
  </div>

  <small *ngIf="error" class="p-error">{{ error }}</small>
  <label *ngIf="label && float" [for]="controlName">{{ label }}</label>
</div>

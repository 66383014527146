<div
  [ngClass]="{
    'sticky-form-container-sticky': isSticky
  }"
>
  <ng-content></ng-content>
  <div class="footer-buttons" [ngClass]="{ fixed: !isSticky && isFixed }" #fd>
    <ng-content></ng-content>
    <hospitality-bot-button
      *ngIf="!preHide"
      [label]="preLabel"
      (onClick)="preAction()"
      [variant]="preVariant"
      [severity]="preSeverity"
      [disabled]="preDisabled || disabled || loading"
      [minWidth]="preWidth"
    ></hospitality-bot-button>
    <hospitality-bot-button
      *ngIf="!postHide"
      [label]="postLabel"
      (onClick)="postAction()"
      [variant]="postVariant"
      [severity]="postSeverity"
      [isLoading]="loading"
      [disabled]="postDisabled || disabled || loading"
      [minWidth]="postWidth"
    ></hospitality-bot-button>
  </div>
</div>

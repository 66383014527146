<div class="wrapper">
  <div class="main" #main>
    <div class="header">
      <div
        *ngFor="let field of fields"
        [style.flexBasis]="field.width ? field.width : width"
        class="header__cell"
      >
        {{ field.label }}
      </div>
    </div>

    <div
      [formArray]="useFormArray"
      *ngFor="let controls of useFormArray.controls; let i = index"
    >
      <div [formGroup]="controls" class="values-row">
        <div
          *ngFor="let field of fields"
          [style.flexBasis]="field.width ? field.width : width"
          [style.width]="field.width ? field.width : width"
          [ngSwitch]="field.type"
        >
          <hospitality-bot-input
            *ngSwitchCase="'input'"
            [controlName]="field.name"
            [disabled]="field.disabled"
            [props]="{
              placeholder: field.placeholder ? field.placeholder : '',
              fontSize: '14px',
              type: field?.dataType,
              errorMessages: field?.errorMessages
            }"
          ></hospitality-bot-input>
          <hospitality-bot-auto-complete
            *ngSwitchCase="'auto-complete'"
            [controlName]="field.name"
            [options]="field.options"
            [disabled]="field.disabled"
            [loading]="field.loadingResults"
            (onSearch)="field.searchResults($event)"
            [props]="{
              placeholder: field.placeholder ? field.placeholder : '',
              fontSize: '14px'
            }"
          ></hospitality-bot-auto-complete>
          <hospitality-bot-select
            *ngSwitchCase="'select'"
            class="select"
            [controlName]="field.name"
            [options]="field.options"
            [disabled]="field.disabled"
            [props]="{
              placeholder: field.placeholder ? field.placeholder : '',
              fontSize: '14px',
              isAsync: field.isAsync,
              createPrompt: field.createPrompt
            }"
            [loading]="field?.loadingResults"
            (paginate)="field?.loadMoreResults()"
            (onSearch)="field?.searchResults($event)"
            [stopEmission]="field?.noMoreResults"
            (onCreate)="field?.create()"
          ></hospitality-bot-select>
          <hospitality-bot-multi-select
            *ngSwitchCase="'multi-select'"
            [controlName]="field.name"
            [props]="{
              placeholder: field.placeholder,
              isAsync: false
            }"
            [options]="field.options"
            [disabled]="field.disabled"
            [settings]="{ showHeader: false }"
          ></hospitality-bot-multi-select>
        </div>
        <ng-container *ngIf="useFormArray.length > 1">
          <mat-icon
            *ngIf="ctaLabel && (!maxLimit || maxLimit > useFormArray.length)"
            class="delete"
            (click)="removeField(i)"
            >clear</mat-icon
          >
        </ng-container>
      </div>
    </div>
  </div>
  <div class="action-btn">
    <hospitality-bot-button
      *ngIf="ctaLabel && (!maxLimit || maxLimit > useFormArray.length)"
      [label]="ctaLabel"
      (onClick)="addNewField()"
      variant="text"
    ></hospitality-bot-button>
  </div>
</div>

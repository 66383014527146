import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BaseChartDirective } from 'ng2-charts';
import {
  DualPlotChartConfig,
  DualPlotDataset,
  DualPlotGraphColor,
  DualPlotOptions,
} from '../../types/chart.type';
import { ChartOptions } from 'chart.js';

const GraphType = {
  LINE: 'line',
  BAR: 'bar',
} as const;
type GraphType = typeof GraphType[keyof typeof GraphType];

type ChartTypeConfig = {
  name: string;
  value: GraphType;
  url: string;
  backgroundColor: string;
};
const chartTypeConfig: ChartTypeConfig[] = [
  {
    name: 'Bar',
    value: GraphType.BAR,
    url: 'assets/svg/bar-graph.svg',
    backgroundColor: '#1AB99F',
  },
  {
    name: 'Line',
    value: GraphType.LINE,
    url: 'assets/svg/line-graph.svg',
    backgroundColor: '#DEFFF3',
  },
];
@Component({
  selector: 'hospitality-bot-dual-plot',
  templateUrl: './dual-plot.component.html',
  styleUrls: ['./dual-plot.component.scss'],
})
export class DualPlotComponent implements OnInit {
  @ViewChild(BaseChartDirective) baseChart: BaseChartDirective;
  useForm: UntypedFormGroup;
  chartTypeOption = chartTypeConfig;

  @ViewChild('myCanvas') canvas: ElementRef;

  @Output() onSelectedTabFilterChange = new EventEmitter(null);

  @Input() graphType: GraphType = GraphType.LINE;
  @Input() isSwitchAble: boolean = false;
  @Input() title: string;
  @Input() labels: string[] = [];

  @Input() icon: string;
  @Input() isFilter: boolean = false;

  @Input() tabFilterOptions: any[];
  @Input() options: ChartOptions = defaultDualPlotOptions; //to customize graph design
  @Input() colors: DualPlotGraphColor[] = defaultDualPlotColors; //plotted graph colors
  @Input() datasets: DualPlotDataset[] = []; //graph data
  @Input() legend: boolean = false;
  @Input() set config(value: DualPlotChartConfig) {
    if (value) {
      Object.assign(this, value);
    }
  }
  @Input() isGradient: boolean = false;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit() {
    if (this.isGradient) {
      this.initGradient();
    }
  }

  initGradient() {
    this.datasets.forEach((item) => {
      const gradient = this.canvas.nativeElement
        .getContext('2d')
        .createLinearGradient(0, 0, 0, 500);

      // Add color stops to transition from item.borderColor to white
      gradient.addColorStop(0, item.borderColor); // Start color
      gradient.addColorStop(0.7, 'white'); // Transition to white
      gradient.addColorStop(1, 'white'); // End color (white)

      item.backgroundColor = gradient;
    });
    this.baseChart.update();
  }

  initForm(): void {
    this.useForm = this.fb.group({
      graphType: [this.graphType],
    });
  }

  setChartType(config: ChartTypeConfig) {
    this.graphType = config.value;
    if (this.graphType === 'line' && this.isGradient) {
      this.initGradient();
    }
  }

  onFilterCLick(index: number) {
    const chart = this.baseChart.chart;

    const alreadyHidden =
      chart?.getDatasetMeta(index).hidden === null
        ? false
        : chart?.getDatasetMeta(index)?.hidden;

    chart?.data?.datasets?.forEach((data, idx) => {
      const meta = chart?.getDatasetMeta(idx);

      if (idx === index) {
        if (!alreadyHidden) {
          meta.hidden = true;
        } else {
          meta.hidden = false;
        }
      }
    });

    chart.update();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.datasets || changes.config) {
      this.initDualPlotColors();
    }
  }

  initDualPlotColors() {}
}

const defaultDualPlotOptions: ChartOptions = {
  responsive: true,
  elements: {
    line: {
      tension: 0,
    },
    point: {
      radius: 4,
      borderWidth: 2,
      hitRadius: 5,
      hoverRadius: 5,
      hoverBorderWidth: 2,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: true,
      },
    },
  },
  plugins: {
    tooltip: {
      backgroundColor: 'white',
      bodyFont: {
        family: 'Arial',
        size: 12,
        weight: 'normal',
        lineHeight: 1.5,
      },
      borderColor: '#f4f5f6',
      borderWidth: 3,
      titleFont: {
        family: 'Arial',
        size: 14,
        weight: 'bold',
        lineHeight: 1.5,
      },
      padding: 10,
    },
  },
};

const defaultDualPlotColors: DualPlotGraphColor[] = [
  {
    borderColor: '#0C8054',
    backgroundColor: '#DEFFF3',
    pointBackgroundColor: 'white',
    pointBorderColor: '#0C8054',
    pointHoverBackgroundColor: 'white',
    pointHoverBorderColor: '#0C8054',
  },
  {
    borderColor: '#ef1d45',
    backgroundColor: '#DEFFF3',
    pointBackgroundColor: 'white',
    pointBorderColor: '#ef1d45',
    pointHoverBackgroundColor: 'white',
    pointHoverBorderColor: '#ef1d45',
  },
];

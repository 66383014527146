<div>
  <div class="row">
    <!-- Here header will come -->
  </div>
  <form [formGroup]="resetPasswordForm">
    <div class="card">
      <h4 class="card--heading">{{ 'reset_password_text' | translate }}</h4>
      <h3 class="card--quote">
        {{ 'reset_password_info_text' | translate }}
      </h3>
      <div class="card-form">
        <div class="form-row">
          <mat-form-field>
            <input
              type="password"
              matInput
              autocomplete="new-password"
              and
              id="new-password"
              formControlName="password"
              placeholder="{{ 'placeholder.new_password' | translate }}"
            />
            <mat-error
              *ngIf="
                resetPasswordForm.get('password').touched &&
                (resetPasswordForm.get('password').hasError('invalidLength') ||
                  resetPasswordForm.get('password').hasError('maxlength'))
              "
            >
              Password length should be between 8 and 20 characters.
            </mat-error>
            <mat-error
              *ngIf="
                resetPasswordForm.get('password').touched &&
                resetPasswordForm.get('password').hasError('required')
              "
            >
              Password is required.
            </mat-error>
            <mat-error
              *ngIf="
                resetPasswordForm.get('password').touched &&
                resetPasswordForm.get('password').hasError('uppercaseStart')
              "
            >
              Password should start with an uppercase alphabet.
            </mat-error>
            <mat-error
              *ngIf="
                resetPasswordForm.get('password').touched &&
                resetPasswordForm.get('password').hasError('noUppercase')
              "
            >
              Password should contain at least one uppercase alphabet.
            </mat-error>
            <mat-error
              *ngIf="
                resetPasswordForm.get('password').touched &&
                resetPasswordForm.get('password').hasError('noNumeric')
              "
            >
              Password should contain at least one numeric value.
            </mat-error>
            <mat-error
              *ngIf="
                resetPasswordForm.get('password').touched &&
                resetPasswordForm.get('password').hasError('nonAlphanumeric')
              "
            >
              Password should contain only alphanumeric characters.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field>
            <input
              [type]="!isPasswordVisible ? 'password' : 'text'"
              matInput
              formControlName="resetPassword"
              placeholder="{{ 'placeholder.confirm_password' | translate }}"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="isPasswordVisible = !isPasswordVisible"
            >
              <mat-icon>{{
                !isPasswordVisible ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </button>
            <mat-error
              *ngIf="
                resetPasswordForm.get('resetPassword').touched &&
                resetPasswordForm.get('resetPassword').hasError('required')
              "
            >
              {{ 'validation.confirm_password' | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                resetPasswordForm.get('resetPassword').touched &&
                resetPasswordForm.get('resetPassword').hasError('passwordMatch')
              "
            >
              {{ 'validation.password_not_match' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="card-footer" (click)="resetPassword()">
        <button class="login" mat-button>
          <i *ngIf="isResetingIn" class="fa fa-spinner fa-spin"></i>
          {{ 'button.save' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>

<div [ngClass]="{ 'back-drop': this.url }">
  <div class="upload__box">
    <div class="before-upload">
      <img src="assets/svg/upload.svg" />
      <p class="upload-label">Upload Image</p>
    </div>
    <img
      file-upload-css
      loading="'lazy'"
      [src]="url"
      class="upload__box__child"
      [style.display]="url ? 'block' : 'none'"
      alt="uploaded-image"
    />
    <input
      #fileInput
      type="file"
      accept="image/*"
      (click)="fileInput.value = null"
      [disabled]="isDisable"
      (change)="onSelectFile($event)"
      class="input-upload"
    />
    <input type="hidden" />
    <mat-label></mat-label>
  </div>
  <ng-container *ngIf="uploadStatus">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-container>

  <div class="edit-pannel">
    <img
      pTooltip="Edit"
      (click)="fileInput.click()"
      src="assets/svg/edit8278.svg"
      alt=""
    />
  </div>
</div>

<div class="wrapper">
  <div class="wrapper--left">
    <div class="wrapper--left-auth-panel">
      <router-outlet></router-outlet>
    </div>
    <footer class="wrapper--left-footer">
      {{ 'agreement_text' | translate }}&nbsp;<a
        [href]="authConstants.linkUrl.termsAndCondition"
        target="_blank"
        rel="noopener"
        >{{ 'terms_text' | translate }}</a
      >
    </footer>
    <div class="wrapper--left-svg-icons first">
      <img [src]="authConstants.imgUrl.bell" [alt]="authConstants.alt.bell" />
    </div>
    <div class="wrapper--left-svg-icons second">
      <img [src]="authConstants.imgUrl.hotel" [alt]="authConstants.alt.hotel" />
    </div>
    <div class="wrapper--left-svg-icons third">
      <img
        [src]="authConstants.imgUrl.luggage"
        [alt]="authConstants.alt.luggage"
      />
    </div>
    <div class="wrapper--left-svg-icons fourth">
      <img
        [src]="authConstants.imgUrl.metroHotel"
        [alt]="authConstants.alt.metroHotel"
      />
    </div>
  </div>
  <div class="wrapper--right">
    <div
      class="wrapper--right--carousel"
      slickContainer
      #slickController="slick"
      [slickConfig]="config"
    >
      <ng-container *ngIf="slickController.initialize">
        <div slickItem></div>
        <div slickItem class="carousel--free">
          <div class="fredabg"></div>
          <div class="freedie">
            <img src="assets/svg/freedie-login.svg" alt="freedie-login" />
          </div>
        </div>
        <div slickItem class="carousel--hed">
          <div class="hedabg"></div>
          <div class="heda">
            <img src="assets/svg/heda-login.svg" alt="heda-login" />
          </div>
        </div>
      </ng-container>
    </div>
    <div class="wrapper--right__top">
      <header class="wrapper--right-header">
        <a
          [href]="authConstants.linkUrl.aboutUs"
          target="_blank"
          rel="noopener"
          >{{ 'menu.about_us' | translate }}</a
        >
        <a
          [href]="authConstants.linkUrl.privacyPolicy"
          target="_blank"
          rel="noopener"
          >{{ 'menu.privacy_policy' | translate }}</a
        >
      </header>
      <div class="wrapper--right-managing-hotel">
        <p class="heading">
          Maximize revenue & streamline your hotel's operations.
        </p>
        <div class="wrapper--right-managing-hotel-option-box">
          <div *ngFor="let option of productList" class="option">
            <img
              class="material-icons"
              [src]="option?.icon"
              [alt]="option?.label"
            />
            <div class="option-container">
              <div class="option-label">{{ option.label }}</div>
              <div class="option-description">
                {{ option?.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper--right-footer">
      <div class="wrapper--right-footer__certification">
        <div class="title">Trusted by industry leaders</div>
        <div class="icons-container">
          <div
            *ngFor="let icons of authConstants?.productCertification"
            class="icons"
          >
            <img [src]="icons.url" [alt]="icons.label" />
          </div>
        </div>
      </div>
      <div class="image">
        <img [src]="authConstants.imgUrl.botshotLogo" alt="Botshot" />
        <span class="copyright">© 2020, Botshot. All Right Reserved</span>
      </div>
    </div>
  </div>
</div>

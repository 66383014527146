import { Injectable, ViewContainerRef } from '@angular/core';
import { DetailsTabOptions } from 'libs/admin/reservation/src/lib/components/details/details.component';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BookingDetailService {
  tabKey: DetailsTabOptions = 'guest_details';
  bookingNumber: string;
  guestId: string;
  bookingId: string;
  actionEvent = new BehaviorSubject<boolean>(false);

  private viewContainerRef: ViewContainerRef;
  private sidebarVisibilityEvent = new Subject<SideBarVisibilityOption>();

  /**
   * Handle Change in route for closing multiple sidebars
   */
  changeRoute = new BehaviorSubject<boolean>(false);

  resetBookingState() {
    this.bookingNumber = undefined;
    this.guestId = undefined;
    this.bookingNumber = undefined;
    this.guestId = undefined;
    this.bookingId = undefined;
    this.actionEvent.next(false);
    this.changeRoute.next(false);
  }

  /**
   *
   * @param data One of the input is required to open details
   */
  openBookingDetailSidebar(data: {
    bookingNumber?: string;
    guestId?: string;
    tabKey?: DetailsTabOptions;
    bookingId?: string;
  }) {
    this.bookingNumber = data?.bookingNumber;
    this.guestId = data?.guestId;
    this.bookingId = data?.bookingId;
    this.tabKey = data?.tabKey ?? 'guest_details';
    this.actionEvent.next(true);
  }

  /**
   * Set the view container reference used to open the side bar.
   * This is required because the sidebar is opened from a different component.
   * @param inputRef the view container reference
   */
  initViewContainerRef(inputRef: ViewContainerRef): void {
    this.viewContainerRef = inputRef;
  }

  /**
   * Return the view container ref required to open the sidebar.
   * @returns the view container reference
   */
  getContainerRef(): ViewContainerRef {
    return this.viewContainerRef;
  }

  initSidebarVisibility(input: SideBarVisibilityOption) {
    this.sidebarVisibilityEvent.next(input);
  }

  getSidebarVisibility(): Subject<SideBarVisibilityOption> {
    return this.sidebarVisibilityEvent;
  }
}

type SideBarVisibilityOption = { isSidebarVisible: boolean; className: string };

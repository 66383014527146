<div class="sorry-message">
  <div class="ellipse">
    <img src="assets/svg/Ellipse1564.svg" />
    <div class="heda-freedie">
      <div class="tree-left">
        <img src="assets/svg/side-tree.svg" />
        <div class="heda-message">
          <p>Hey Freedie,Where is this page?</p>
        </div>
      </div>
      <div class="sorry-heda">
        <img src="assets/svg/Heda-icon.svg" />
      </div>
      <div class="sorry-freedie">
        <img src="assets/svg/Freedie-icon.svg" />
        <div class="freedie-message">
          <p>
            Sorry Heda,this page does not exist! Maybe go to our home page or
            try to use a search?
          </p>
        </div>
      </div>
      <div class="tree-right">
        <img src="assets/svg/side-tree.svg" />
      </div>
    </div>
    <div class="button">
      <button (click)="redirectToDashboard()">
        Go back to Dashboard
      </button>
    </div>
  </div>
</div>

<div
  [class]="controlName"
  [ngClass]="wrapperNgClasses"
  [formGroup]="controlContainer.control"
>
  <label
    *ngIf="label"
    class="labelc"
    [ngClass]="{
      float__label: float,
      label__static: !float,
      'float-inside__label': floatInsideLabel
    }"
    [for]="controlName"
    >{{ label }}</label
  >

  <p-multiSelect
    [class]="isEmpty ? 'input no-select' : 'input'"
    [ngClass]="multiSelectDropDownClass"
    [disabled]="isDisabled || null"
    [formControlName]="controlName"
    [inputId]="controlName"
    [options]="menuOptions"
    [dropdownIcon]="dropdownIcon"
    (onPanelShow)="onMenuOpen()"
    (onPanelHide)="onMenuClose()"
    [showHeader]="showHeader"
    [defaultLabel]="placeholder"
    [resetFilterOnHide]="true"
    [maxSelectedLabels]="maxSelectedLabels"
    [selectedItemsLabel]="selectedItemsLabel"
    [appendTo]="isAppendToBody ? 'body' : null"
    [selectedItemsLabel]="selectedItemsLabel"
  >
    <ng-template *ngIf="showChips" let-value pTemplate="selectedItems">
      <div class="chips">
        <div class="placeholder" *ngIf="!value?.length">{{ placeholder }}</div>
        <div class="chip" [click-stop-propagation] *ngFor="let val of value">
          <div>{{ dictionary[val] }}</div>
          <i class="pi pi-times" (click)="handleClear(val)"></i>
        </div>
      </div>
    </ng-template>

    <ng-template let-option pTemplate="item">
      <div class="option-items" *ngIf="!option.hidden">
        <span class="option-label">{{ option.label }}</span>
        <span
          *ngIf="option?.extras && option?.extras.length"
          class="option-extras"
        >
          {{ option.extras }}
        </span>
      </div>
    </ng-template>
  </p-multiSelect>

  <small *ngIf="error" class="p-error">{{ error }}</small>
</div>

import { FormArray, FormGroup } from '@angular/forms';
import { merge, Observable } from 'rxjs';
import { map, distinctUntilChanged, debounceTime } from 'rxjs/operators';

export class FormArrayValueChanges {
  static getValueChanges<T = any>(
    formArray: FormArray,
    excludedControlName: string[],
    debounceTimeMs: number = 300
  ): Observable<T[]> {
    const formGroupObservables = formArray.controls.map((control, index) =>
      FormArrayValueChanges.createFormGroupObservable(
        control as FormGroup,
        index,
        excludedControlName
      )
    );

    return merge(...formGroupObservables).pipe(
      debounceTime(debounceTimeMs),
      map(() => formArray.value as T[]),
      distinctUntilChanged(
        (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
      )
    );
  }

  private static createFormGroupObservable(
    formGroup: FormGroup,
    index: number,
    excludedControlName: string[]
  ): Observable<{ index: number; key: string; value: any }> {
    const controlObservables = Object.keys(formGroup.controls)
      .filter((key) => !excludedControlName.includes(key))
      .map((key) =>
        formGroup
          .get(key)!
          .valueChanges.pipe(map((value) => ({ index, key, value })))
      );

    return merge(...controlObservables);
  }
}

<div class="doughnut__wrapper">
  <div class="doughnut__title">
    {{ label }}
  </div>

  <div class="wrapper">
    <div class="doughnut__graph">
      <div
        class="outer"
        *skeleton="loading; height: '25vw'; width: '25vw'; borderRadius: '50%'"
      >
        <canvas
          baseChart
          class="graph-base-chart"
          [data]="doughnutTransparentGraphData.data"
          [type]="doughnutTransparentGraphData.type"
          [options]="doughnutTransparentGraphData.options"
        >
        </canvas>
      </div>
      <div class="inner" *ngIf="!loading">
        <canvas
          baseChart
          class="graph-base-chart"
          [data]="doughnutGraphData.data"
          [type]="doughnutGraphData.type"
          [legend]="doughnutGraphData.legend"
          [options]="doughnutGraphData.options"
          (chartHover)="handleCircularGraphClick($event)"
        >
        </canvas>
      </div>
      <div class="doughnut__graph__stat" *ngIf="!loading">
        <div class="text">
          {{ doughnutGraphData?.total || 0 }}
        </div>
      </div>
    </div>
  </div>
</div>

<p-table
  #dt
  [styleClass]="tableConfig.styleClass"
  [resizableColumns]="isResizableColumns"
  [autoLayout]="isAutoLayout"
  [loading]="loading"
  [columns]="cols"
  [value]="values"
  [paginator]="isPaginator"
  [rows]="rowsPerPage"
  [showCurrentPageReport]="showCurrentPageReport"
  [rowsPerPageOptions]="rowsPerPageOptions"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [(first)]="first"
  [totalRecords]="totalRecords"
  [filterDelay]="0"
  [(selection)]="selectedRows"
>
  <ng-template pTemplate="caption">
    <div class="caption__wrapper">
      <form [formGroup]="tableFG">
        <div class="caption__wrapper--tabmenu">
          <div class="header">
            <p class="heading">{{ tableName }}</p>
            <div class="action-wrapper">
              <hospitality-bot-button label="Create"></hospitality-bot-button>
              <hospitality-bot-export-list
                [additionalActionItems]="additionalActionItems"
                (onDocumentActions)="onDocumentActions()"
                [documentTypes]="documentTypes"
                [documentActionTypes]="documentActionTypes"
              ></hospitality-bot-export-list>
            </div>
          </div>

          <hospitality-bot-tab-group
            *ngIf="isTabFilters"
            [listItems]="tabFilterItems"
          ></hospitality-bot-tab-group>
        </div>
        <hospitality-bot-filter-chips
          *ngIf="isQuickFilters"
          [chips]="filterChips"
          (onChange)="toggleQuickReplyFilter($event)"
        ></hospitality-bot-filter-chips>
      </form>
    </div>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr hospitality-bot-empty-view [noOfColumns]="cols.length"></tr>
  </ng-template>
  <!-- *******************************HEADER WITH SEARCH******************************* -->
  <ng-template pTemplate="header" let-columns #header>
    <tr
      hospitality-bot-table-header
      [columns]="columns"
      [showCheckbox]="isSelectable"
      [showSearch]="isSearchable"
      (search)="handleFieldSearch($event)"
    ></tr>
  </ng-template>
  <!-- **************************************BODY********************************* -->
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-rowIndex="rowIndex"
  >
    <tr class="data-table-row">
      <td class="table--checkbox">
        <p-tableCheckbox [value]="rowData" [index]="rowIndex"></p-tableCheckbox>
      </td>
      <td>
        <div class="cell-content">
          {{ rowData.name }}
        </div>
      </td>
      <td>
        <div class="cell-content">
          {{ rowData.date }}
        </div>
      </td>
      <td>
        <div class="cell-content">
          {{ rowData.role }}
        </div>
      </td>
      <td>
        <div class="cell-content">
          {{ rowData.status }}
        </div>
      </td>
      <td>
        <div class="cell-content">
          <hospitality-bot-status-dropdown-toggle
            (onClick)="handleStatus($event, rowData.name)"
            [state]="rowData.status"
            [nextStates]="status"
          >
          </hospitality-bot-status-dropdown-toggle>
        </div>
      </td>
    </tr>
  </ng-template>
  <!-- *********************************FOOTER******************************* -->
  <ng-template pTemplate="summary">
    <p-paginator
      #paginator
      [rows]="rowsPerPage"
      [totalRecords]="totalRecords"
      [rowsPerPageOptions]="rowsPerPageOptions"
      (onPageChange)="paginate($event)"
      [currentPageReportTemplate]="reportTemplate"
      showCurrentPageReport="true"
    ></p-paginator>
  </ng-template>
</p-table>

<div>
  <div class="row"></div>
  <div class="card">
    <h4 class="card--heading">
      {{ 'sent_mail_text' | translate }}
    </h4>
    <h3 class="card--quote">
      {{ 'sent_mail_info_text' | translate }}
    </h3>
    <h3 class="card--quote">
      {{ 'resend_mail_info_text' | translate }}
    </h3>
    <div class="card-footer">
      <button class="login cancel" mat-button (click)="navigateToLogin()">
        {{ 'button.cancel' | translate }}
      </button>
      <button class="login" mat-button (click)="navigateToRequestPassword()">
        {{ 'button.resend_mail' | translate }}
      </button>
    </div>
  </div>
</div>

<div class="wrapper">
  <div class="header">
    <div class="heading-wrapper">
      <div class="title">
        <h1 *ngIf="header" class="heading">{{ header }}</h1>
        <div class="close" (click)="onCloseSidebar.emit()">
          <img class="close-sidebar-icon" src="assets/svg/close-sidebar.svg" />
        </div>
      </div>
      <hospitality-bot-tab-header
        [listItems]="tabFilterItems"
        [selectedIndex]="tabFilterIdx"
        (selectedTabChange)="onTabFilterChange($event.index)"
      ></hospitality-bot-tab-header>
      <div class="header__filter">
        <ng-container *ngFor="let date of dates; let i = index">
          <div
            (click)="
              onSelectedDateRangeChange(dates[i]);
              selectedDateFilter = date.date
            "
            class="card"
            [ngClass]="{ card__selected: selectedDateFilter === date.date }"
          >
            <span> {{ date.day }}</span>
            <span>{{ date.date }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    class="container"
    infiniteScroll
    [toggleScrollListener]="paginationDisabled"
    (scrolled)="loadMore()"
    *ngIf="!loading; else loader"
  >
    <ng-container *ngIf="options.length; else empty">
      <div *ngFor="let item of options">
        <ng-container
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{ $implicit: item }"
        >
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loader>
  <hospitality-bot-loader-bounce class="loader"></hospitality-bot-loader-bounce>
</ng-template>

<ng-template #empty>
  <div class="empty-container">
    <div class="content">
      <img [src]="emptyMessage.img" alt="" />
      <span class="title">{{ emptyMessage.title }}</span>
    </div>
  </div>

  <ng-template> </ng-template
></ng-template>

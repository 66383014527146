import { DateService } from '@hospitality-bot/shared/utils';

export class BuildRateAndInventoryRequestData {
  inventoryList: InventoryList[];

  deserialize(input: UpdateRateFormData, dateLimit = 14) {
    this.inventoryList = Array<InventoryList>();

    //this will convert date to entity specific time with (0,0,0,0) time(hh:mm:ss:ms)
    const startDate = DateService.reverseOffset(
      new Date(input.date).setHours(0, 0, 0, 0)
    ).getTime();

    let nextDate = startDate;

    for (let i = 0; i < dateLimit; i++) {
      this.inventoryList.push(
        new InventoryList().deserialize(nextDate, input, i)
      );
      nextDate = DateService.getNextDateEpoch(nextDate);
    }

    this.inventoryList = filterNullDateRangeData(this.inventoryList);

    return this;
  }
}

class InventoryList {
  dateRanges: DateRange[];
  roomTypes: RoomType[];

  deserialize(startDate: number, input: UpdateRateFormData, dateIndex: number) {
    this.dateRanges = new Array<DateRange>();
    this.roomTypes = new Array<RoomType>();

    this.dateRanges.push(new DateRange().deserialize(startDate));

    input.roomTypes.forEach((roomTypeData) => {
      //check if the room type is selected
      if (input.roomType.includes(roomTypeData.id))
        this.roomTypes.push(
          new RoomType().deserialize(roomTypeData, dateIndex)
        );
    });

    return this;
  }
}
class RoomType {
  id: string;
  ratePlans: RatePlans[];
  available: number;
  stopSell: boolean;

  deserialize(roomTypeData: RoomTypeData, dateIndex: number) {
    this.id = roomTypeData.id;

    if (roomTypeData?.ratePlans?.length) {
      this.ratePlans = roomTypeData.ratePlans.map((ratePlan) =>
        new RatePlans().deserialize(ratePlan, dateIndex)
      );
    }

    this.stopSell = (roomTypeData?.inventory?.find(
      (item) => item?.label === 'Stop Sell'
    )?.rowData?.[dateIndex]?.value as unknown) as boolean;

    this.available = (roomTypeData?.inventory?.find(
      (item) => item?.label === 'Available'
    )?.rowData?.[dateIndex]?.value as unknown) as number;

    return this;
  }
}
export class RatePlans {
  id: string;
  paxPriceList: PaxPriceList[];
  additionalGuestPrice: AdditionalGuestPrice;

  deserialize(ratePlan: RatePlanData, dateIndex: number) {
    this.paxPriceList = new Array<PaxPriceList>();
    this.additionalGuestPrice = new AdditionalGuestPrice();

    this.id = ratePlan.id;
    ratePlan.pax.forEach((paxData) => {
      this.paxPriceList.push(
        new PaxPriceList().deserialize(paxData, dateIndex)
      );
    });

    ratePlan.extras.forEach((extras) => {
      this.additionalGuestPrice[extras.key] = getValue(
        extras?.rowData?.[dateIndex]?.value
      );
    });

    return this;
  }
}

export class PaxPriceList {
  pax: string;
  rate: number;

  deserialize(paxPrice: PaxData, dateIndex: number) {
    this.pax = paxPrice.id;
    this.rate = getValue(paxPrice.rowData[dateIndex].value);

    return this;
  }
}

class AdditionalGuestPrice {
  adultPrice: number;
  childRangeOne: number;
  childRangeTwo: number;
  childRangeThree: number;

  deserialize(extraData: PaxData, dateIndex: number) {
    return this;
  }
}

class DateRange {
  fromDate: number;
  toDate: number;
  days?: string[];

  deserialize(input: number) {
    this.fromDate = input;
    this.toDate = input;
    return this;
  }
}

function getValue(price: any) {
  if (price === '' || price === undefined || price === null) {
    return null;
  } else {
    return Number(price);
  }
}

export function filterNullDateRangeData<T extends InventoryList>(
  inventoryData: T[]
) {
  return inventoryData
    .map((item) => {
      const filteredRoomTypes = item.roomTypes
        .map((roomType) => {
          const filteredRatePlans = roomType?.ratePlans
            ?.map((ratePlan) => {
              const filteredPaxPriceList = ratePlan?.paxPriceList?.filter(
                (paxPrice) =>
                  paxPrice?.rate || paxPrice?.rate?.toString()?.trim()?.length
              );

              const hasInValidAdditionalGuestPrice = Object.values(
                ratePlan?.additionalGuestPrice ?? {}
              )?.every(
                (value) => value === null || !value?.toString()?.trim()?.length
              );

              return {
                ...ratePlan,
                paxPriceList: filteredPaxPriceList?.length
                  ? filteredPaxPriceList
                  : undefined,
                additionalGuestPrice: hasInValidAdditionalGuestPrice
                  ? undefined
                  : ratePlan?.additionalGuestPrice,
              };
            })
            ?.filter(
              (ratePlan) =>
                ratePlan?.paxPriceList?.length > 0 ||
                Object.values(ratePlan?.additionalGuestPrice ?? {})?.length > 0
            );

          return {
            ...roomType,
            ratePlans: filteredRatePlans,
          };
        })
        .filter(
          (roomType) =>
            roomType?.available ||
            roomType?.available?.toString()?.length ||
            roomType.stopSell ||
            roomType?.stopSell?.toString()?.length ||
            roomType?.ratePlans?.length > 0
        );

      return {
        ...item,
        roomTypes: filteredRoomTypes,
      };
    })
    .filter((item) => item?.roomTypes?.length > 0) as T[];
}

type UpdateRateFormData = {
  roomType: string[];
  selectBar: string;
  date: number;
  roomTypes: RoomTypeData[];
};

class BulkUpdateRateFormData {
  dateRangeForm: DateRangeForm[];
  selectBar: string;
  inventoryTreeList: RoomTypeData[];
}

type RoomTypeData = {
  id: string;
  order: null;
  label: string;
  value: number;
  isBaseRoomType: boolean;
  basePrice: number;
  ratePlans: RatePlanData[];
  isSelected: boolean;
  linked: boolean;
  ratePlanVisible: boolean;
  rowData: Rate[];
  available: number;
  inventory: Inventory[];
  dynamicPrice: any[];
};

interface Inventory {
  label: string;
  value: null;
  type: string;
  linked: boolean;
  rowData: RowDatum2[];
}

interface RowDatum2 {
  value: boolean | null;
}

type RatePlanData = {
  id: string;
  basePrice: null | number | number;
  value: number;
  label: string;
  subLabel: string;
  linked: boolean;
  showChannels: boolean;
  isBase: boolean;
  selectedRestriction: string[];
  paxVisible: boolean;
  isSelected: boolean;
  pax: PaxData[];
  extras: any[];
  rates: Rate[];
};

interface Rate {
  value: null;
}

type PaxData = {
  id: string;
  basePrice: number;
  value: number;
  label: string;
  subLabel: string;
  linked: boolean;
  showChannels: boolean;
  isBase: boolean;
  selectedRestriction: string[];
  paxVisible: boolean;
  isSelected: boolean;
  rowData: RowDatum[];
};

interface RowDatum {
  label: string;
  value: number;
}

type DateRangeForm = {
  fromDate: number;
  toDate: number;
  selectedDays: string[];
};

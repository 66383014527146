<div class="container">
  <div class="header" [formGroup]="useForm">
    <div class="title-container">
      <div class="icon" *ngIf="icon">
        <img [src]="icon" alt="icon" />
      </div>
      <div class="stat-title">{{ title }}</div>
    </div>

    <div class="menu-container">
      <div class="pull-right" *ngIf="isSwitchAble">
        <label class="labl" *ngFor="let option of chartTypeOption">
          <input
            placeholder="radio"
            type="radio"
            formControlName="graphType"
            [value]="option.value"
            (click)="setChartType(option)"
          />
          <div class="image">
            <img [src]="option.url" alt="" />
          </div>
        </label>
      </div>
      <ng-content></ng-content>
    </div>
  </div>

  <hospitality-bot-tab-group
    *ngIf="tabFilterOptions?.length"
    [listItems]="tabFilterOptions"
    (selectedTabChange)="onSelectedTabFilterChange.emit($event)"
  >
  </hospitality-bot-tab-group>

  <div class="line-chart">
    <canvas
      #myCanvas
      *skeleton="datasets == undefined; height: '41vh'; width: '100%'"
      baseChart
      class="line-chart__canvas"
      [datasets]="datasets"
      [labels]="labels"
      [options]="options"
      [legend]="legend"
      [type]="graphType"
    >
    </canvas>
  </div>

  <div class="stat-filter" *ngIf="isFilter">
    <div
      class="stat-filter-label"
      (click)="onFilterCLick(filterIndex)"
      *ngFor="let filter of datasets; let filterIndex = index"
    >
      <input
        class="filters"
        type="checkbox"
        *skeleton="
          datasets == undefined;
          height: '35px';
          width: '90px';
          margin: '0 0 10px 0'
        "
      />

      <ng-container>
        <div
          class="legend-border"
          *ngIf="!filter.fill"
          [style.backgroundColor]="filter?.backgroundColor"
        ></div>

        <div
          *ngIf="filter.fill"
          class="hexagon"
          [style.backgroundColor]="filter?.borderColor"
        ></div>
      </ng-container>

      <p class="stat-filter-label-title">
        {{ filter.label }}
      </p>
    </div>
  </div>
</div>

<div class="wrapper">
  <header class="wrapper-header">
    <mat-icon (click)="back()" class="material-icons" matSuffix
      >keyboard_backspace</mat-icon
    >
    <p class="label">
      {{ label }}
    </p>
  </header>
  <p class="label drafted-text">Drafted on {{ draftDate }}</p>
</div>

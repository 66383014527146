import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidatorService } from '@hospitality-bot/admin/shared';
import { SnackBarService } from '@hospitality-bot/shared/material';
import { passwordValidator } from '../../constants/auth';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';

/**
 * @class Reset Password Component.
 */
@Component({
  selector: 'admin-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm: UntypedFormGroup;
  isPasswordVisible = false;
  changePasswordToken: string;
  isResetingIn: boolean = false;
  private $subscription = new Subscription();

  constructor(
    private _fb: UntypedFormBuilder,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _snackbarService: SnackBarService,
    private _authService: AuthService,
    private _validatorService: ValidatorService
  ) {
    this.initResetForm();
  }

  ngOnInit(): void {
    this.getChangePasswordToken();
  }

  /**
   * @function initResetForm To initialize the reset password form.
   */
  initResetForm(): void {
    this.resetPasswordForm = this._fb.group(
      {
        password: ['', [Validators.required, passwordValidator()]],
        resetPassword: ['', [Validators.required]],
      },
      {
        validator: this._validatorService.matchPasswords(
          'password',
          'resetPassword'
        ),
      }
    );
  }

  /**
   * @function getChangePasswordToken To get the value of change password token.
   */
  getChangePasswordToken(): void {
    this.$subscription.add(
      this._activatedRoute.queryParams.subscribe((params) => {
        if (params['token']) this.changePasswordToken = params['token'];
      })
    );
  }

  /**
   * @function resetPassword To reset user password.
   */
  resetPassword(): void {
    if (!this.resetPasswordForm?.valid) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }

    this.isResetingIn = true;

    const data = {
      token: this.changePasswordToken,
      password: this.resetPasswordForm?.get('password')?.value,
    };

    this.$subscription.add(
      this._authService.changePassword(data).subscribe(
        (response) => {
          this._snackbarService
            .openSnackBarWithTranslate(
              {
                translateKey: 'messages.success.reset_password',
                priorityMessage: response?.message,
              },
              '',
              { panelClass: 'success' }
            )
            .subscribe();

          this.isResetingIn = false;

          this.navigateToLogin();
        },
        ({ error }) => {
          this.isResetingIn = false;

          this._snackbarService
            .openSnackBarWithTranslate(
              {
                translateKey: 'messages.error.some_thing_wrong',
                priorityMessage: error?.message,
              },
              ''
            )
            .subscribe();
        }
      )
    );
  }

  /**
   * @function navigateToLogin To navigate to login page.
   */
  navigateToLogin(): void {
    this._router.navigate(['/auth/login']);
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }
}

<div
  class="wrapper p-field"
  [style.fontSize]="fontSize"
  [ngClass]="getWrapperNgClasses"
  [formGroup]="controlContainer.control"
>
  <label
    *ngIf="label && !float"
    class="label label__static"
    [for]="controlName"
    >{{ label }}</label
  >

  <hospitality-bot-custom-tooltip
    *ngIf="additionalInfo"
    [text]="additionalInfo"
    class="additional-info"
    mode="standalone"
  ></hospitality-bot-custom-tooltip>

  <input
    [ngClass]="getInputClass"
    [attr.disabled]="isDisabled || null"
    [formControlName]="controlName"
    [id]="controlName"
    [attr.type]="type"
    pInputText
    [maxlength]="maxLength"
    [min]="min"
    [max]="max"
    [placeholder]="placeholder"
    (keydown)="handleKeyClick($event)"
    [tabindex]="tabIndex"
    (wheel)="disableValueChangesOnScroll($event)"
    (click)="$event.stopPropagation()"
    (focus)="onFocus.emit($event)"
  />
  <!-- (wheel)="$event.preventDefault(); $event.stopPropagation()" -->

  <ng-container *ngIf="!isLoading">
    <small *ngIf="subtitle" class="subtitle"
      ><ng-container *ngIf="!strike; else strikeText">{{
        subtitle
      }}</ng-container>
    </small>
    <small *ngIf="viewButton" class="save-cta">
      <a class="input-btn" type="submit" (click)="onSaveText.emit()">
        {{ buttonText }}
      </a>
    </small>
    <ng-template #strikeText>
      <del>{{ subtitle }}</del>
    </ng-template>
  </ng-container>

  <small *ngIf="error && isErrorVisible" class="p-error"> {{ error }}</small>

  <label *ngIf="label && float" [for]="controlName" class="label">{{
    label
  }}</label>
  <i
    *ngIf="isLoading"
    class="pi pi-spin pi-spinner"
    [ngClass]="iconNgClass"
  ></i>
</div>

<div class="item-view__main" [ngClass]="{ 'item-view__disabled': isDisabled }">
  <div class="wrapper input" [ngClass]="inputNgClasses">
    <ng-container *ngIf="items.length; else empty">
      <div
        *ngFor="let item of items; let i = index"
        class="item"
        [ngClass]="{
          reserved: item?.disabled,
          active: isActive(item),
          pointer: !item?.disabled
        }"
        title="{{ item?.label }}"
        (click)="!item?.disabled && click(item)"
      >
        {{ item?.label }}
      </div>
    </ng-container>
  </div>
  <small *ngIf="error" class="p-error"> {{ error }}</small>
</div>

<ng-template #empty>
  <div class="empty-view">
    <hospitality-bot-loader-bounce
      *ngIf="isLoading; else empty"
    ></hospitality-bot-loader-bounce>
    <ng-template #empty>
      <div class="empty-container">
        <div class="content">
          <span class="title">No Table Found</span>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>

<div #tabFilter>
  <div
    *ngIf="isSticky && isScrolledUp"
    [ngStyle]="{ 'height.px': extraGap }"
  ></div>

  <div
    class="tab-wrapper"
    [ngClass]="{ sticky__tabs: isScrolledUp && isSticky }"
  >
    <hospitality-bot-tab-group
      class="tabs"
      [selectedIndex]="selectedIndex"
      [listItems]="listItems"
      (selectedTabChange)="onSelectedTabChange($event)"
    >
    </hospitality-bot-tab-group>
    <div class="tab-actions">
      <ng-content></ng-content>
    </div>
  </div>
</div>

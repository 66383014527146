<div
  class="dates"
  [formGroup]="parentFG"
  [ngClass]="{ dates__dashed: showDash }"
>
  <div class="field">
    <hospitality-bot-date
      class="bulk-update__date"
      [ngClass]="className"
      [controlName]="controlNames.from"
      [label]="showLabel ? labels.from : ''"
      [props]="{
        placeholder: isTimeOnly ? 'HH:MM A' : 'DD/MM/YYYY',
        errorMessages: preErrorMessages,
        floatInsideLabel: floatInsideLabel
      }"
      [settings]="{
        isTimeOnly: isTimeOnly,
        enableTime: isTimeEnable,
        showIcon: showIcon
      }"
      [minDate]="!isTimeOnly && startMinDate"
      [maxDate]="startMaxDate"
      [resetDate]="resetDate"
    ></hospitality-bot-date>
  </div>
  <div *ngIf="showDash" class="hypen"></div>
  <div class="field">
    <hospitality-bot-date
      class="bulk-update__date"
      [ngClass]="className"
      [controlName]="controlNames.to"
      [label]="showLabel ? labels.to : ''"
      [props]="{
        placeholder: isTimeOnly ? 'HH:MM A' : 'DD/MM/YYYY',
        errorMessages: postErrorMessages,
        floatInsideLabel: floatInsideLabel
      }"
      [settings]="{
        isTimeOnly: isTimeOnly,
        enableTime: isTimeEnable,
        showIcon: showIcon
      }"
      [minDate]="!isTimeOnly && endMinDate"
      [maxDate]="!isTimeOnly && endMaxDate"
      [resetDate]="resetDate"
    ></hospitality-bot-date>
  </div>
</div>

import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Inventory,
  InventoryItem,
  RestrictionAndValuesOption,
} from '../../../../../channel-manager/src/lib/constants/bulkupdate-rates.contant';
import {
  extrasConfig,
  rateAndRestrictions,
  roomTypeConfig,
} from './channel-manger.const';
import { Injectable } from "@angular/core";
type ChangeDetectorResponse = { value: string; indexToBeChanged: number };

@Injectable()
export class BuildInventoryTreeControl {
  private parentFormArray: UntypedFormArray;
  private rowLength: number = 15;
  private inventoryData: Inventory;
  private roomTypeConfig = roomTypeConfig;
  private rateAndRestrictions = rateAndRestrictions;
  public isExtraConfig: boolean = true;

  initConfig(data: {
    roomTypeConfig?: RestrictionAndValuesOption[];
    rateAndRestrictions?: RestrictionAndValuesOption[];
    extrasConfig?: InventoryItem[];
  }) {
    Object.assign(this, data);
  }

  /**
   * Build the tree control structure for the inventory data.
   * @param {Inventory} inventoryData - The inventory data to build the tree control for.
   * @returns {FormArray} - The FormArray representing the tree control structure.
   */
  buildTreeControl(
    inventoryData: Inventory,
    type: 'ALL' | 'INVENTORY' | 'RATES' = 'ALL'
  ): UntypedFormArray {
    this.parentFormArray = new UntypedFormArray([]);
    this.inventoryData = inventoryData;

    inventoryData?.roomType?.forEach((roomType) => {
      const currentRoomTypeFG: UntypedFormGroup = this.createRoomTypeControl(roomType);
      this.addRatePlanFormArray(
        inventoryData.ratePlan ?? roomType.ratePlan,
        currentRoomTypeFG
      );

      switch (type) {
        case 'ALL':
          this.listenForRoomTypeValueChange(currentRoomTypeFG, roomType.isBase);
          this.addRoomTypeInventoryControl(currentRoomTypeFG); //Restrictions
          break;

        case 'INVENTORY':
          this.addRoomTypeInventoryControl(currentRoomTypeFG); //Restrictions
          break;

        default:
          this.listenForRoomTypeValueChange(currentRoomTypeFG, roomType.isBase);
      }

      // Add the roomType FormGroup to the parent FormArray
      this.parentFormArray.controls.push(currentRoomTypeFG);
      this.addRoomDynamicPriceControl(currentRoomTypeFG);
    });

    return this.parentFormArray;
  }

  private listenForRoomTypeValueChange(
    currentRoomTypeFG: UntypedFormGroup,
    isBase: boolean
  ) {
    const ratePlansFA = currentRoomTypeFG.get('ratePlans') as UntypedFormArray;
    const baseRatePlanControl = this.getBaseControl(ratePlansFA);

    // Update base ratePlan value when roomType value changes
    currentRoomTypeFG
      .get('value')
      ?.valueChanges.subscribe((newRoomTypePrice) => {
        baseRatePlanControl.get('value').setValue(newRoomTypePrice);
      });

    // subscribe to room type value change events and updated other room types
    currentRoomTypeFG
      .get('value')
      .valueChanges.subscribe((baseRoomTypePrice) => {
        if (isBase) {
          //update other room type price fields
          this.parentFormArray.controls.forEach((currentRoomTypeControl) => {
            if (!currentRoomTypeControl.get('isBaseRoomType').value) {
              const currentRoomTypeBaseRate = currentRoomTypeControl.get(
                'basePrice'
              ).value;

              currentRoomTypeControl
                .get('value')
                .patchValue(
                  this.calculatePrice(
                    currentRoomTypeBaseRate,
                    baseRoomTypePrice
                  )
                );
            }
          });
        }
      });

    //subscribe to change detector
    currentRoomTypeFG
      .get('changeDetector')
      .valueChanges.subscribe((res: ChangeDetectorResponse) => {
        const rowDataFA = currentRoomTypeFG.get('rowData') as UntypedFormArray;

        //update base rate plan
        baseRatePlanControl?.get('changeDetector').setValue(res);

        if (res.indexToBeChanged !== null) {
          rowDataFA
            .at(res.indexToBeChanged)
            .get('value')
            .patchValue(res.value, { emitEvent: false });
        } else {
          //update current roomType rowData
          currentRoomTypeFG.get('rowData').patchValue(
            this.getArray(
              {
                value: res.value,
              } as any,
              undefined,
              this.rowLength
            ),
            {
              emitEvent: false,
            }
          );
        }

        if (isBase) {
          //update other room type price fields
          this.parentFormArray.controls.forEach((currentRoomTypeControl) => {
            if (!currentRoomTypeControl.get('isBaseRoomType').value) {
              const currentRoomTypeBaseRate = currentRoomTypeControl.get(
                'basePrice'
              ).value;

              currentRoomTypeControl.get('changeDetector').patchValue({
                value: this.calculatePrice(currentRoomTypeBaseRate, res.value),
                indexToBeChanged: res.indexToBeChanged,
              });
            }
          });
        }
      });

    //subscribe to selection change
    currentRoomTypeFG
      .get('isSelected')
      .valueChanges.subscribe((isRoomTypeSelected) => {
        ratePlansFA.controls.forEach((control) => {
          control.get('isSelected').setValue(isRoomTypeSelected);
        });
      });
  }

  private addRoomTypeInventoryControl(roomTypeFG: UntypedFormGroup) {
    const rateAndRestrictionsFA = new UntypedFormArray([]);

    this.roomTypeConfig?.forEach((restriction) => {
      const rowDataFA = this.getValuesArrayControl(restriction.type);

      const rateAndRestrictionFormGroup = new UntypedFormGroup({
        label: new UntypedFormControl(restriction.label),
        value: new UntypedFormControl(null),
        type: new UntypedFormControl(restriction.type),
        linked: new UntypedFormControl(false),
        rowData: rowDataFA,
      });

      rowDataFA.controls.forEach(
        (restrictionRowControl, restrictionRowIndex) => {
          restrictionRowControl.valueChanges.subscribe((res) => {
            //if restriction is stop sell then and if it is enabled then patch 0 corresponding availability and disable input field
            if (restriction.value === 'STOP_SELL') {
              //extract corresponding availability control
              const availableRowFA = rateAndRestrictionsFA
                .at(0)
                .get('rowData') as UntypedFormArray;

              if (rateAndRestrictionFormGroup.get('linked').value) {
                //update available row with new value as 0 and mark disabled if stop sell is enabled
                res.value &&
                  availableRowFA.patchValue(
                    this.getArray({ value: 0 }, rowDataFA, this.rowLength),
                    {
                      emitEvent: false,
                    }
                  );
                //updating available rows
                availableRowFA.controls.forEach((currentAvailableControl) => {
                  res.value
                    ? currentAvailableControl.disable({
                        emitEvent: false,
                      })
                    : currentAvailableControl.enable({
                        emitEvent: false,
                      });
                });
              } else {
                //if stop sell in not linked then update on corresponding index
                res.value &&
                  availableRowFA
                    .at(restrictionRowIndex)
                    .get('value')
                    .patchValue(0, {
                      emitEvent: false,
                    });

                res.value
                  ? availableRowFA.at(restrictionRowIndex).disable({
                      emitEvent: false,
                    })
                  : availableRowFA.at(restrictionRowIndex).enable({
                      emitEvent: false,
                    });
              }
            }

            if (rateAndRestrictionFormGroup.get('linked').value) {
              if (res.value === '') {
                res.value = null;
              }

              rowDataFA.controls.forEach((control) => {
                if (!control.disabled)
                  control.patchValue(res, {
                    emitEvent: false,
                  });
              });
            }
          });
        }
      );

      rateAndRestrictionsFA.push(rateAndRestrictionFormGroup);
    });

    roomTypeFG.addControl('inventory', rateAndRestrictionsFA);
  }

  /**
   * Create a FormGroup for a room type.
   * @param {InventoryItem} roomType - The room type data.
   * @returns {FormGroup} - The FormGroup representing the room type.
   */
  private createRoomTypeControl(roomType: InventoryItem): UntypedFormGroup {
    const rowDataFA = this.getValuesArrayControl('number');

    const currentRoomTypeGroup = new UntypedFormGroup({
      id: new UntypedFormControl(roomType?.id),
      order: new UntypedFormControl(roomType?.order),
      label: new UntypedFormControl(roomType?.name),
      value: new UntypedFormControl(null),
      isBaseRoomType: new UntypedFormControl(roomType?.isBase),
      basePrice: new UntypedFormControl(roomType?.baseRate),
      ratePlans: new UntypedFormArray([]),
      isSelected: new UntypedFormControl(true),
      linked: new UntypedFormControl(false),
      ratePlanVisible: new UntypedFormControl(false),
      rowData: rowDataFA,
      available: new UntypedFormControl(null),
      //important
      changeDetector: new UntypedFormControl(null),
    });

    rowDataFA.controls.forEach((control, index) => {
      control.valueChanges.subscribe((res) => {
        const isLinked: boolean = currentRoomTypeGroup.get('linked').value;

        // currentRoomTypeGroup.get('value').setValue(res.value);

        if (isLinked) {
          rowDataFA.patchValue(this.getArray(res, rowDataFA, this.rowLength), {
            emitEvent: false,
          });
        }
        currentRoomTypeGroup?.get('changeDetector').setValue({
          value: res.value,
          indexToBeChanged: isLinked ? null : index,
        });
      });
    });

    return currentRoomTypeGroup;
  }

  /**
   * Add rate plans to the room type FormGroup.
   * @param {Inventory} inventoryData - The inventory data.
   * @param {FormGroup} roomTypeFG - The FormGroup representing the room type.
   */
  private addRatePlanFormArray(
    ratePlan: InventoryItem[],
    roomTypeFG: UntypedFormGroup
  ) {
    const ratePlansFA = roomTypeFG.get('ratePlans') as UntypedFormArray;

    ratePlan?.forEach((ratePlan) => {
      const currentRatePlanFG: UntypedFormGroup = this.createControl(
        ratePlan,
        'ratePlan'
      );

      //add ratplan rates restrictions
      this.addRatesAndRestrictions(undefined, currentRatePlanFG, roomTypeFG);

      const ratePlanRowDataFA = currentRatePlanFG.get('rates') as UntypedFormArray;
      /**
       * listen rate plans selection change
       */
      this.listenForRatePlansSelectionChange(
        currentRatePlanFG,
        roomTypeFG,
        ratePlansFA
      );

      //subscribe to value also update base pax price
      currentRatePlanFG.get('value')?.valueChanges.subscribe((price) => {
        const basePaxControl = this.getBaseControl(
          currentRatePlanFG.get('pax') as UntypedFormArray
        );
        //update base pax so, other pax are updated accordingly
        basePaxControl?.get('value')?.setValue(price);

        //update all extras
        (currentRatePlanFG.get('extras') as UntypedFormArray).controls.forEach(
          (control) => {
            const value =
              price && control.get('basePrice').value
                ? control.get('basePrice').value
                : this.calculatePrice(0, price);

            control.get('value').setValue(value, {
              emitEvent: false,
            });
          }
        );

        //if base ratePlan will change then other ratePlan price will change accordingly
        if (ratePlan.isBase) {
          ratePlansFA?.controls?.forEach((currentRatePlanControl) => {
            if (!currentRatePlanControl.get('isBase')?.value) {
              const currentRatePlanBaseRate = currentRatePlanControl.get(
                'basePrice'
              )?.value;

              currentRatePlanControl
                ?.get('value')
                ?.patchValue(
                  this.calculatePrice(currentRatePlanBaseRate, price)
                );
            }
          });
        }
      });

      //subscribe to change Detector and update rate plan row
      currentRatePlanFG
        .get('changeDetector')
        .valueChanges.subscribe((res: ChangeDetectorResponse) => {
          const basePaxControl = this.getBaseControl(
            currentRatePlanFG.get('pax') as UntypedFormArray
          );

          //update base pax so, other pax are updated accordingly
          basePaxControl?.get('changeDetector')?.setValue(res);

          (currentRatePlanFG.get('extras') as UntypedFormArray).controls.forEach(
            (control) => {
              control.get('changeDetector').setValue(res);
            }
          );

          if (res.indexToBeChanged !== null) {
            ratePlanRowDataFA
              .at(res.indexToBeChanged)
              .get('value')
              .patchValue(res.value, {
                emitEvent: false,
              });
          } else {
            //update current ratePlan rowData
            ratePlanRowDataFA.patchValue(
              this.getArray(
                {
                  value: res.value,
                } as any,
                undefined,
                this.rowLength
              ),
              {
                emitEvent: false,
              }
            );
          }

          if (ratePlan.isBase) {
            ratePlansFA?.controls?.forEach((currentRatePlanControl) => {
              if (!currentRatePlanControl.get('isBase')?.value) {
                const currentRatePlanBaseRate = currentRatePlanControl.get(
                  'basePrice'
                )?.value;

                currentRatePlanControl?.get('changeDetector')?.patchValue({
                  value: this.calculatePrice(
                    currentRatePlanBaseRate,
                    res.value
                  ),
                  indexToBeChanged: res.indexToBeChanged,
                });
              }
            });
          }
        });

      //push control to ratePlanFA
      ratePlansFA.push(currentRatePlanFG);

      this.addPaxFormArray(
        this.inventoryData.roomOccupancy ?? ratePlan?.pax,
        currentRatePlanFG,
        ratePlan
      );
      if (this.isExtraConfig)
        this.addExtraFormArray(ratePlan?.extras, currentRatePlanFG);
    });
  }

  /**
   * Listen for RatePlan Selection Change
   * @param {FormGroup} currentRatePlanFG - The RatePlan formGroup
   * @param {FormGroup} roomTypeFG - The FormGroup representing the room type.
   * @param {FormArray} ratePlansFA - The FormArray representing the rate plans
   *
   */
  private listenForRatePlansSelectionChange(
    currentRatePlanFG: UntypedFormGroup,
    roomTypeFG: UntypedFormGroup,
    ratePlansFA: UntypedFormArray
  ) {
    const paxFA = currentRatePlanFG.get('pax') as UntypedFormArray;
    const extrasFA = currentRatePlanFG.get('extras') as UntypedFormArray;

    /**
     *
     * if ratePlans isSelected chang then mark all pax and extras as per rate plan selection change
     *
     */
    currentRatePlanFG
      .get('isSelected')
      .valueChanges.subscribe((isRatePlanSelected) => {
        paxFA.controls.forEach((control) => {
          control.get('isSelected').setValue(isRatePlanSelected);
        });

        extrasFA.controls.forEach((control) => {
          control.get('isSelected').setValue(isRatePlanSelected);
        });

        //also check if all rate plans are selected then respective roomType will be selected
        const isRoomTypeSelected = !ratePlansFA.controls.some(
          (control) => !control.get('isSelected').value
        );

        //set respective room type values as per rate plan selection
        roomTypeFG
          .get('isSelected')
          .setValue(isRoomTypeSelected, { emitEvent: false });
      });
  }

  /**
   * @function addRatesAndRestrictions
   * @param {Inventory} inventoryData
   * @param {FormGroup } currentRatePlanFG
   * @param {FormGroup}roomTypeFG
   */
  private addRatesAndRestrictions(
    inventoryData: Inventory,
    currentRatePlanFG: UntypedFormGroup,
    roomTypeFG: UntypedFormGroup
  ) {
    const isBaseRoomType: boolean = roomTypeFG.get('isBaseRoomType')?.value;
    const isBaseRatePlan: boolean = currentRatePlanFG.get('isBase')?.value;

    this.rateAndRestrictions?.forEach((rateAndRestriction) => {
      const currentRestrictionsFA = this.getValuesArrayControl(
        rateAndRestriction.type
      );

      currentRestrictionsFA?.controls?.forEach((control, index) => {
        control.valueChanges.subscribe((updatedPrice) => {
          const isLinked = currentRatePlanFG.get('linked')?.value;

          if (isLinked) {
            //update row
            currentRestrictionsFA.patchValue(
              this.getArray(
                updatedPrice,
                currentRestrictionsFA,
                this.rowLength
              ),
              {
                emitEvent: false,
              }
            );
          }

          currentRatePlanFG.get('changeDetector').patchValue({
            value: updatedPrice.value,
            indexToBeChanged: isLinked ? null : index,
          });

          //update main price controller
          // currentRatePlanFG.get('value').setValue(updatedPrice.value);

          if (isBaseRoomType && isBaseRatePlan) {
            /**
             *
             * logic:  update roomType base rate and other rate Plan rates
             */

            this.parentFormArray?.controls?.forEach((roomTypeControl) => {
              if (!roomTypeControl?.get('isBaseRoomType')?.value) {
                const currentRoomTypeBasePrice = roomTypeControl.get(
                  'basePrice'
                )?.value;

                roomTypeControl.get('changeDetector')?.patchValue({
                  value: this.calculatePrice(
                    currentRoomTypeBasePrice,
                    updatedPrice.value
                  ),
                  indexToBeChanged: isLinked ? null : index,
                });
              }
            });
          }
        });
      });

      currentRatePlanFG.addControl(
        rateAndRestriction.value,
        currentRestrictionsFA
      );
    });
  }

  private getValuesArrayControl(
    type: RestrictionAndValuesOption['type'] = 'number'
  ) {
    return new UntypedFormArray(
      Array.from(
        { length: this.rowLength },
        () =>
          new UntypedFormGroup({
            value:
              type === 'number'
                ? new UntypedFormControl(null, [Validators.min(0)])
                : new UntypedFormControl(null),
          })
      )
    );
  }

  private addPaxFormArray(
    paxData: InventoryItem[],
    ratePlanFG: UntypedFormGroup,
    ratePlanData: InventoryItem
  ) {
    const paxFA = ratePlanFG.get('pax') as UntypedFormArray;

    paxData?.forEach((roomOccupancy) => {
      const currentPaxFG = this.createControl(roomOccupancy, 'pax');

      const configuredBasePrice = this.calculateBaseRate(
        ratePlanData,
        roomOccupancy
      );
      currentPaxFG
        .get('basePrice')
        .setValue(configuredBasePrice, { emitEvent: false });

      /**
       * listen for pax select change
       */
      this.listenPaxAndExtraSelectionChange(currentPaxFG, ratePlanFG);

      /**
       * if base pax value change then update other pax values
       */
      if (roomOccupancy.isBase) {
        currentPaxFG.disable();
      }

      currentPaxFG.get('value').valueChanges.subscribe((newPrice) => {
        //if base pax then change all other pax acc
        if (roomOccupancy.isBase) {
          paxFA?.controls?.forEach((currentPaxControl) => {
            if (!currentPaxControl.get('isBase')?.value) {
              const currentPaxBasePrice = currentPaxControl.get('basePrice')
                ?.value;

              currentPaxControl
                ?.get('value')
                ?.patchValue(
                  this.calculatePrice(currentPaxBasePrice, newPrice)
                );
            }
          });
        }
      });

      //subscribe to changes Detection
      this.subscribeToChangeDetector(
        currentPaxFG,
        roomOccupancy.isBase ? paxFA : null
      );

      //subscribe rowData change
      this.listenPaxAndExtraChanges(currentPaxFG);
      paxFA.push(currentPaxFG);
    });
  }

  private addExtraFormArray(
    extrasData: InventoryItem[],
    ratePlanFG: UntypedFormGroup
  ) {
    const extraFA = ratePlanFG.get('extras') as UntypedFormArray;

    extrasData?.forEach((extraData) => {
      const currentExtraFG = this.createControl(extraData, 'extra');
      //subscribe to changes Detection
      this.subscribeToChangeDetector(
        currentExtraFG,
        null,
        currentExtraFG.get('basePrice').value
      );
      this.listenPaxAndExtraChanges(currentExtraFG, false);
      this.listenPaxAndExtraSelectionChange(currentExtraFG, ratePlanFG);
      extraFA.controls.push(currentExtraFG);
    });
  }

  private subscribeToChangeDetector(
    currentFG: UntypedFormGroup,
    ChildFA?: UntypedFormArray,
    newPrice?: number
  ) {
    currentFG
      .get('changeDetector')
      .valueChanges.subscribe((res: ChangeDetectorResponse) => {
        const extraRowDataFA = currentFG.get('rowData') as UntypedFormArray;

        const price =
          res.value && newPrice ? newPrice : this.calculatePrice(0, res.value);

        if (res.indexToBeChanged !== null) {
          extraRowDataFA
            .at(res.indexToBeChanged)
            .get('value')
            .patchValue(price, { emitEvent: false });
        } else {
          extraRowDataFA.patchValue(
            this.getArray(
              { value: price } as any,
              extraRowDataFA,
              this.rowLength
            ),
            {
              emitEvent: false,
            }
          );
        }

        if (ChildFA) {
          ChildFA?.controls?.forEach((currentPaxControl) => {
            if (!currentPaxControl.get('isBase')?.value) {
              const currentPaxBasePrice = currentPaxControl.get('basePrice')
                ?.value;

              currentPaxControl?.get('changeDetector')?.patchValue({
                value: this.calculatePrice(currentPaxBasePrice, res.value),
                indexToBeChanged: res.indexToBeChanged,
              });
            }
          });
        }
      });
  }

  private listenPaxAndExtraSelectionChange(
    currentPaxFG: UntypedFormGroup,
    ratePlanFG: UntypedFormGroup
  ) {
    const paxFA = ratePlanFG.get('pax') as UntypedFormArray;
    const extraFA = ratePlanFG.get('extras') as UntypedFormArray;

    currentPaxFG.get('isSelected').valueChanges.subscribe((_) => {
      const isRatePlanSelected =
        !paxFA.controls.some((control) => !control.get('isSelected').value) &&
        !extraFA.controls.some((control) => !control.get('isSelected').value);

      ratePlanFG
        .get('isSelected')
        .setValue(isRatePlanSelected, { emitEvent: false });
    });
  }

  private createControl(
    item: InventoryItem,
    type: 'extra' | 'pax' | 'ratePlan'
  ): UntypedFormGroup {
    const group: { [key: string]: UntypedFormControl | UntypedFormArray } = {
      id: new UntypedFormControl(item?.id),
      basePrice: new UntypedFormControl(item?.baseRate),
      value: new UntypedFormControl(null, [Validators.min(0)]),
      label: new UntypedFormControl(item?.name),
      subLabel: new UntypedFormControl(''),
      linked: new UntypedFormControl(false),
      showChannels: new UntypedFormControl(false),
      isBase: new UntypedFormControl(item?.isBase),
      selectedRestriction: new UntypedFormControl(['rates']),
      paxVisible: new UntypedFormControl(false),
      isSelected: new UntypedFormControl(true),
      key: new UntypedFormControl(item?.key),
      //important
      changeDetector: new UntypedFormControl(null),
    };

    if (type === 'pax' || type === 'extra') {
      group['rowData'] = new UntypedFormArray(
        Array.from({ length: this.rowLength }, () => {
          return new UntypedFormGroup({
            label: new UntypedFormControl(''),
            value: new UntypedFormControl(null),
          });
        })
      );
    }

    if (type === 'ratePlan') {
      group['pax'] = new UntypedFormArray([]);
      group['extras'] = new UntypedFormArray([]);
    }

    return new UntypedFormGroup(group);
  }

  private addRoomDynamicPriceControl(roomTypeFG: UntypedFormGroup) {
    roomTypeFG.addControl('dynamicPrice', new UntypedFormArray([]));
    /**
     * TODO: complete this
     */
  }

  private getArray(value?: any, restrictionFA?: UntypedFormArray, dateLimit?: number) {
    if (restrictionFA && value !== undefined) {
      restrictionFA.controls.map((fg) => value);
    }

    if (value !== undefined) {
      return new Array(dateLimit).fill(value);
    }
    return new Array(dateLimit).fill(dateLimit);
  }

  private listenPaxAndExtraChanges(
    currentPaxFG: UntypedFormGroup,
    triggerChangeDetector: boolean = true
  ) {
    const paxRowData = currentPaxFG.get('rowData') as UntypedFormArray;

    paxRowData?.controls?.forEach(
      (paxControl: AbstractControl, paxIdx: number) => {
        paxControl.valueChanges.subscribe(
          (res: { label: string; value: string }) => {
            const isLinked = currentPaxFG?.get('linked')?.value;

            //update current pax row if its is liked
            if (isLinked) {
              paxRowData?.controls?.forEach(
                (control: AbstractControl, dayIdx: number) => {
                  // const dpArray = this.getDynamicPriceFA(roomTypeIdx);
                  const isDisable = false; //dpArray.at(dayIdx).get('value').value;

                  const samePrice = control?.get('value')?.value;

                  //update main controller
                  currentPaxFG.get('value').patchValue(res.value);

                  control.patchValue(
                    {
                      value: isDisable
                        ? samePrice
                        : res?.value?.length
                        ? res.value
                        : null,
                    },
                    { emitEvent: false }
                  );
                }
              );
            }

            triggerChangeDetector &&
              currentPaxFG.get('changeDetector').patchValue({
                value: res?.value,
                indexToBeChanged: isLinked ? null : paxIdx,
              } as ChangeDetectorResponse);
          }
        );
      }
    );
  }

  /**
   * Get the base control from a FormArray.
   * @param {FormArray} formArray - The FormArray to search.
   * @returns {AbstractControl} - The base control found in the FormArray.
   */
  private getBaseControl(formArray: UntypedFormArray): AbstractControl {
    return formArray.controls.find((control) => control.get('isBase').value);
  }

  private calculateBaseRate(
    ratePlan: InventoryItem,
    paxData: InventoryItem
  ): number {
    // Extract and validate pax count
    const paxCount = parseInt(paxData.id, 10) - 1;

    const validPaxCount =
      isNaN(paxCount) || paxCount === 0 ? 1 : Math.abs(paxCount);

    // Validate and default base rates
    const roomTypeBaseRate = 0; //no need to add roomType base rate
    const ratePlanBaseRate = ratePlan?.baseRate ?? 0;
    const paxDataBaseRate = paxData?.baseRate ?? 0;

    // Calculate the rate
    const calculatedRate =
      roomTypeBaseRate + ratePlanBaseRate * validPaxCount + paxDataBaseRate;

    return calculatedRate;
  }
  private calculatePrice(
    basePrice: string | number,
    inputPrice: string | number
  ) {
    const regex = /^[0-9]+$/;

    if (
      typeof inputPrice === 'number' ||
      (typeof inputPrice === 'string' && inputPrice?.match(regex))
    ) {
      return +basePrice + +inputPrice;
    } else {
      return null;
    }
  }
}

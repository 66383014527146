<div
  [class]="fieldClass"
  [ngClass]="wrapperNgClasses"
  [style.fontSize]="fontSize"
  [formGroup]="controlContainer.control"
>
  <label
    *ngIf="label && !float"
    class="label label__static"
    [for]="controlName"
    [ngClass]="{ 'float-inside__label': floatInsideLabel }"
    >{{ label }}</label
  >

  <p-dropdown
    #dropdown
    class="input"
    [ngClass]="selectDropDownClass"
    [disabled]="isDisabled || null"
    [options]="dropdownOption"
    [placeholder]="placeholder"
    [showClear]="showClear"
    [formControlName]="controlName"
    [id]="controlName"
    [autoDisplayFirst]="false"
    [dropdownIcon]="dropdownIcon"
    [autofocusFilter]="isAutoFocusFilter"
    (onShow)="onMenuOpen()"
    (onHide)="onMenuClose()"
    (onFocus)="handleFocus()"
    (onBlur)="handleBlur()"
    (onChange)="handleItemChange($event)"
    [filter]="isAsync"
    [filterBy]="stopEmission ? 'label' : 'nothing'"
    [filterMatchMode]="stopEmission ? 'contains' : 'notEquals'"
    [resetFilterOnHide]="true"
    [group]="isGroupOptions"
    [appendTo]="isAppendToBody ? 'body' : null"
  >
    <ng-template let-option pTemplate="item">
      <div class="option-item-wrapper">
        <!-- main label -->
        <div
          class="option-items"
          *ngIf="!option.hidden"
          [ngStyle]="{ display: option.icon ? 'flex' : 'unset' }"
        >
          <img
            *ngIf="option.icon"
            [src]="option.icon"
            class="option-image"
            [alt]="option.label"
          />
          <span
            [ngClass]="{ 'sub-items': isGroupOptions }"
            class="option-label"
            >{{ option.label }}</span
          >
        </div>

        <!-- additional information -->

        <div class="option-additional">
          <span
            *ngIf="option?.extras && option?.extras.length"
            class="option-name"
          >
            {{ option.extras }}
          </span>

          <!-- Offer description -->
          <div
            *ngIf="option?.offerDescription"
            [innerHTML]="option?.offerDescription"
            class="option-name"
          ></div>

          <span
            *ngIf="option.phoneNumber || option.email"
            class="option-details"
          >
            <span [ngStyle]="{ 'padding-right': '10px' }">{{
              option.phoneNumber
            }}</span>
            <span>{{ option.email }}</span>
          </span>

          <span *ngIf="option?.itemAmount" class="option-amount">
            {{ option.itemAmount | dynamicCurrency }}
          </span>
          <span *ngIf="option?.validDate" class="option-name">
            Valid Till - {{ option.validDate | date: 'dd/MM/yy' }}
          </span>
        </div>
      </div>
    </ng-template>

    <ng-template let-option pTemplate="selectedItem">
      <div
        class="selected-item"
        [ngStyle]="{ display: option?.icon ? 'flex' : 'unset' }"
      >
        <div *ngIf="option.icon">
          <img
            [src]="option.icon"
            class="selected-image"
            [alt]="option.label"
          />
        </div>
        <span class="option-label">{{ option.label }}</span>
      </div>
    </ng-template>

    <ng-template let-group pTemplate="group">
      <div class="option-items">
        <div *ngIf="group.icon">
          <img [src]="group.icon" class="selected-image" [alt]="group.label" />
        </div>
        <span class="option-label">{{ group.label }}</span>
      </div>
    </ng-template>
  </p-dropdown>

  <small *ngIf="error" class="p-error">{{ error }}</small>
  <label *ngIf="label && float" [for]="controlName">{{ label }}</label>
</div>

<div
  [class]="controlName"
  [ngClass]="wrapperNgClasses"
  [formGroup]="controlContainer.control"
>
  <label
    *ngIf="label"
    class="labelc"
    [ngClass]="{
      float__label: float,
      label__static: !float,
      'float-inside__label': floatInsideLabel
    }"
    [for]="controlName"
    >{{ label }}</label
  >
  <p-chips
    #chips
    class="input"
    [ngClass]="inputNgClasses"
    [placeholder]="placeholder"
    [formControlName]="controlName"
    [inputId]="controlName"
    [addOnBlur]="true"
    (onFocus)="handleFocus()"
    (onRemove)="handleRemove($event)"
    (onAdd)="handelOnAdd($event)"
    [maxlength]="maxLength"
  >
    <ng-template let-item pTemplate="item">
      <div>
        {{ item?.label ? item.label : item }}
      </div>
    </ng-template>
  </p-chips>
  <ng-content></ng-content>
  <small *ngIf="error" class="p-error">{{ error }}</small>
</div>

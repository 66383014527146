<div class="wrapper">
  <div class="wrapper__header">
    <div class="title">
      Crop Image
      <div class="clear-icon" (click)="closeModal()">
        &times;
      </div>
    </div>

    <div class="crop-tools">
      <img
        src="assets/svg/rotate-left.svg"
        (click)="rotateLeft()"
        class="icon"
        alt="Rotate right"
      />
      <img
        src="assets/svg/rotate-right.svg"
        (click)="rotateRight()"
        class="icon"
        alt="Rotate right"
      />
      <img
        src="assets/svg/horizontal-flip.svg"
        (click)="flipHorizontal()"
        class="icon"
        alt="Flip Horizintally"
      />
      <img
        src="assets/svg/flip-vertical.svg"
        (click)="flipVertical()"
        class="icon"
        alt="Flip Vertically"
      />
      <img
        src="assets/svg/zoom.svg"
        (click)="zoomIn()"
        class="icon"
        alt="Zoom In"
      />
      <img
        src="assets/svg/zoom-out.svg"
        (click)="zoomOut()"
        class="icon"
        alt="Zoom Out"
      />
    </div>
  </div>

  <div class="crop-image-wrapper">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="containWithinAspectRatio"
      [aspectRatio]="4 / 3"
      [resizeToWidth]="1024"
      [cropperMinWidth]="128"
      [onlyScaleDown]="true"
      [roundCropper]="false"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
      [alignImage]="'left'"
      [style.display]="showCropper ? null : 'none'"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady($event)"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>
  <div class="button-wrapper">
    <div
      class="next-button primary-image-btn btn-default"
      (click)="sendCroppedImage()"
    >
      Confirm
    </div>
  </div>
</div>

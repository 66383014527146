import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { Editor } from 'primeng/editor';

@Component({
  selector: 'hospitality-bot-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss'],
})
export class TemplateEditorComponent implements OnInit {
  parentFG: UntypedFormGroup;
  @Input() controlName: string;
  @Input() template = '';
  @Input() disabled = false;
  @Input() hybrid = true;
  @Input() height: number = 250;
  @Input() isSimpleEditor = false;
  isReadOnly = true;
  @ViewChild('plainTextControl') plainTextControl;
  richText = true;
  ckeConfig = {
    versionCheck: false,
    notification: {
      securityNotifications: false,
    },
    allowedContent: true,
    extraAllowedContent: '*(*);*{*};div(*);a(*)',
    readOnly: false,
    height: this.height,
    disallowedContent: 'img[src]',
  };

  @ViewChild('primeEditor') primeEditor: Editor;

  @Input() type: 'ck' | 'default' = 'default';

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.parentFG = this.controlContainer.control as UntypedFormGroup;
    if (this.isSimpleEditor) {
      this.ckeConfig['toolbar'] = [
        ['Bold', 'Italic', 'Strike', 'RemoveFormat'],
        ['NumberedList', 'BulletList', 'BlockQuote', 'Indent', 'Outdent'],
        ['Link', 'Unlink', 'Anchor'],
        ['Format', 'Styles', '-', 'Source'],
      ];
    }

    this.ckeConfig['readOnly'] = this.disabled;
  }

  ngOnChanges() {
    this.ckeConfig['readOnly'] = this.disabled;
    this.ckeConfig['height'] = this.height;
  }

  ngAfterViewInit() {
    this.preventScrollOnFocus();
  }

  /**
   * @Issue: Due to Quill Editor, page is auto-scrolling to the editor when it gains focus.
   * @Solution: Implementing logic to prevent page scrolling to the editor.
   */
  preventScrollOnFocus() {
    setTimeout(() => {
      this.isReadOnly = false;
    }, 1000);
  }

  changeField(value: boolean) {
    this.richText = value;
    if (!value)
      this.template = this.parentFG.get(this.controlName).value + '\n';
  }

  onTemplateChange(value) {
    this.parentFG.get(this.controlName).setValue(value);
  }
}

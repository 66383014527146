<div
  class="wrapper"
  (click)="onOuterDivClick($event)"
  *ngIf="value !== undefined; else emptyView"
>
  <p-splitButton
    #splitButton
    class="button"
    [ngStyle]="
      menuOptions.length ? { 'flex-basis': '90%' } : { 'flex-basis': '100%' }
    "
    [label]="label"
    (onClick)="toggleState()"
    [model]="items"
    [styleClass]="styleClass"
    [disabled]="disabled"
    appendTo="body"
  ></p-splitButton>

  <hospitality-bot-menu
    *ngIf="!toggleMenu && menuOptions.length"
    type="button"
    alignment="vertical"
    (onClickItem)="handleMenuClick($event)"
    [menuItems]="menuOptions"
    [disabled]="isDisabledMenuCta"
  ></hospitality-bot-menu>

  <hospitality-bot-toggle-menu
    *ngIf="toggleMenu && menuOptions.length"
    type="button"
    class="p-toggle-menu"
    alignment="vertical"
    (onClickItem)="handleMenuClick($event)"
    [menuItems]="menuOptions"
    [disabled]="isDisabledMenuCta"
  >
  </hospitality-bot-toggle-menu>
</div>

<ng-template #emptyView>
  <div class="emptyView">&mdash;</div>
</ng-template>
